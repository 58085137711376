import * as Yup from 'yup';
import libphonenumber from 'google-libphonenumber';
import { COUNTRY_CODES, SCHEMA_TYPE } from '../constants/Constants';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const registerWithEmailSchema = Yup.object().shape({
  first_name: Yup.string()
    .label('First Name')
    .trim()
    .required('Please enter your First Name')
    .max('20', 'firstname must be under 20 characters'),
  last_name: Yup.string()
    .label('Last Name')
    .trim()
    .required('Please enter your Last Name')
    .max('20', 'last name must be under 20 characters'),
  password: Yup.string().label('Password').required('Please enter your password'),
});

Yup.addMethod(Yup.string, 'phone', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Must be a phone number',
    test: (value) => {
      try {
        const phone = phoneUtil.parse(value, 'IN');
        return phoneUtil.isValidNumber(phone);
      } catch (e) {
        return false;
      }
    },
  });
});

export const phoneNumberSchema = Yup.object().shape({
  phone_number: Yup.string()
    .trim()
    .required('Please enter your mobile phone')
    .phone('Hey, that number looks to be invalid'),
});

export const phoneNumberOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .required('Please enter otp')
    .test('len', 'Must be exactly 4 characters', (val) => val && val.toString().length === 4),
});

export const createAccountSchema = Yup.object().shape({
  first_name: Yup.string()
    .label('First name')
    .trim()
    .required('Please enter your first name')
    .max('20', 'first name must be under 20 characters'),
  last_name: Yup.string()
    .label('Last name')
    .trim()
    .required('Please enter your last name')
    .max('20', 'last name must be under 20 characters'),
  username: Yup.string().trim().required('Please enter username').min('3', 'Username should be atleast 3 character'),
  gender: Yup.string().required('Please select gender'),
  image: Yup.object().shape({ uri: Yup.string().notRequired('Please enter profile pic') }),
  cover_image: Yup.object().shape({ uri: Yup.string() }),
  bio: Yup.string().max('70', 'Bio must be under 70 characters'),
});

export const signupSchema = Yup.object().shape({
  first_name: Yup.string()
    .label('First name')
    .trim()
    .required('Please enter your first name')
    .max('20', 'first name must be under 20 characters'),
  last_name: Yup.string()
    .label('Last name')
    .trim()
    .required('Please enter your last name')
    .max('20', 'last name must be under 20 characters'),
  email: Yup.string().trim().email('Email is not valid').label('Email').required('Please enter your email'),
  password: Yup.string().label('Password').required('Please enter your password'),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  accept_conditions: Yup.boolean()
    .label('Accept terms & conditions')
    .required('Please accept term & conditions to continue'),
});

export const googleSignInOnboardingSchemaAdmin = (phoneLength) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .label('First name')
      .trim()
      .required('Please enter your first name')
      .max('20', 'first name must be under 20 characters'),
    last_name: Yup.string().label('Last name').trim().max('20', 'last name must be under 20 characters'),
    email: Yup.string().trim().email('Email is not valid').label('Email').required('Please enter your email'),
    mobile: Yup.string()
      .required('Mobile number is required')
      .trim()
      .matches(`^[0-9]{${phoneLength}}$`, `Mobile number must be exactly ${phoneLength} digits`)
      .matches(/^[0-9]+$/, 'Mobile number must contain only digits'),
  });
};

export const signUpSchemaAdmin = (phoneLength, phoneCode) => {
  return Yup.object().shape({
    first_name: Yup.string()
      .label('First name')
      .trim()
      .required('Please enter your first name')
      .max('20', 'first name must be under 20 characters'),
    last_name: Yup.string()
      .label('Last name')
      .trim()
      .required('Please enter your last name')
      .max('20', 'last name must be under 20 characters'),
    email: Yup.string().trim().email('Email is not valid').label('Email').required('Please enter your email'),
    password: Yup.string()
      .label('Password')
      .required('Please enter your password')
      .min(6, 'Password should be at least 6 characters.'),
    mobile: Yup.string()
      .required('Mobile number is required')
      .trim()
      .matches(/^[0-9]+$/, 'Mobile number must contain only digits')
      .test('length-check', function (value) {
        if (phoneCode === COUNTRY_CODES.KR) {
          const regex = /^(?:\d{10}|\d{11})$/;
          return (
            regex.test(value) ||
            this.createError({
              message: 'Mobile number must be between 10 and 11 digits',
            })
          );
        } else {
          const regex = new RegExp(`^[0-9]{${phoneLength}}$`);
          return (
            regex.test(value) ||
            this.createError({
              message: `Mobile number must be exactly ${phoneLength} digits`,
            })
          );
        }
      }),
  });
};

export const createVaidationSchema = ({ phoneLength, phoneCode, type }) => {
  switch (type) {
    case SCHEMA_TYPE.SELF_SERVE_SIGNUP:
      return signUpSchemaAdmin(phoneLength, phoneCode);
    case SCHEMA_TYPE.SELF_SERVE_ONBOARDING:
      return googleSignInOnboardingSchemaAdmin(phoneLength);
  }
};
