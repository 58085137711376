import { LockClosedIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { createVaidationSchema } from '../../schema/registerSchema';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import { handleDetailsAnalytics } from '../../constants/utils';
import MobileNumberField from '../../components/formField/MobileField';
import { useLocation } from 'react-router-dom';
import { adminSignUpAddPhoneNumber } from '../../services/authServices';
import { useHistory } from 'react-router-dom';
import { EVENTS, SCHEMA_TYPE } from '../../constants/Constants';

export default function OnboardingProfileInfo() {

  const localUserData = JSON.parse(sessionStorage.getItem('userData'));

  const location = useLocation();
  const userData = location?.state
  const [firstname, setFirstname] = useState(userData?.fname || localUserData?.fname || '');
  const [lastname, setLastname] = useState(userData?.lname || localUserData?.lname || '');
  const [mobile, setMobile] = useState('')
  const [phoneCode, setPhoneCode] = useState('')
  const [phoneLength, setPhoneLength] = useState('')
  const currentPhoneLength = useSelector(state => state?.User?.countryPhoneLength);
  const userPhoneCountryCode = useSelector(state => state?.User?.userPhoneCountryCode);
  const communityData = useSelector((state) => state?.community?.createCommunityData);
  const countryCode = useSelector(state => state?.User?.userPhoneCountryCode)
  const history = useHistory()

  useEffect(() => {
    if(!userData || !localUserData) {
      history.replace('/');
    }
  }, [])

  useEffect(() => {
    if(currentPhoneLength){
      setPhoneLength(currentPhoneLength)
      setPhoneCode(userPhoneCountryCode)
    }
  }, [currentPhoneLength])

  const handleAdminSignUp = async (values, actions) => {
    actions.setSubmitting(true);

    // used to prefill the data in the community redirect book a call form
    sessionStorage.setItem(
      'prefill-data',
      JSON.stringify({
        email: userData?.email,
        phone_number: values?.mobile,
        phone_country_code: values?.country_code,
        first_name: values?.first_name,
        last_name: values?.last_name,
      }),
    );

    const valueObj = {
        user: {
          first_name: values?.first_name,
          last_name: values?.last_name,
        },
        contact: {
          phone_number: values?.mobile,
          phone_country_code: values?.country_code
      }
    };
    try {
      const response = await adminSignUpAddPhoneNumber(valueObj);
      const { status, data } = response;
      if (status >= 200 && status < 300 && data) {
        const user = response;
        console.log("user => ", user)
        history.push('/community/appearance');
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(
          parseAxiosErrorMessage(err) || $translatei18n('YouAlreadyHaveACommunityPleaseLogin'),
        );
        history.push('/admin/login');
      } else {
        console.log(err?.response?.data?.error_code);
        NotificationService.error(parseAxiosErrorMessage(err) || $translatei18n('SomethingWentWrong'));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };



  return (
    <MainLayout>
      <div className="min-h-full w-full flex">
        <LeftMainPanel>
          <div className="flex-1 flex justify-center g:flex-none ">
            <div className="mx-auto px-1 w-full">
              <div>
                <h2 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
                  {$translatei18n('CompleteYourDetails')}
                </h2>
                <p className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
                  {$translatei18n('YoureOneAtepAwayFromCreatingYourCommunity')}
                </p>
              </div>
              <div className="mt-6">
                <div className="mt-2 pb-32">
                  <Formik
                    initialValues={{
                      first_name:  firstname || '',
                      last_name: lastname || '',
                      email: userData?.email || localUserData?.email || '',
                      mobile: mobile || '',
                      country_code: phoneCode ? phoneCode : countryCode || ''
                    }}
                    validationSchema={createVaidationSchema({phoneLength: phoneLength, type: SCHEMA_TYPE.SELF_SERVE_ONBOARDING})}
                    onSubmit={(values, actions) => handleAdminSignUp(values, actions)}
                    enableReinitialize
                  >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                      <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="grid xs:grid-cols-1 grid-cols-2 gap-2">
                          <div
                            className="mt-4"
                            data-aos="fade"
                            data-aos-offset={-300}
                            data-aos-delay={200}
                            data-aos-duration="500"
                            data-aos-easing="ease-in-out"
                            data-aos-anchor-placement="center"
                            data-aos-once="false"
                          >
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 text__title">
                              {$translatei18n('FirstName')}
                            </label>
                            <div className="mt-1">
                              <input
                                id="first_name"
                                name="first_name"
                                type="text"
                                required
                                className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                                onChange={(e) => {
                                  setFieldValue('first_name', e.target.value);
                                  setFirstname(e.target.value);
                                }}
                                value={values.first_name}
                                onBlur={(e) => {
                                  handleDetailsAnalytics(
                                    EVENTS.AMPLITEUDE.ONBOARDING_GOOGLE_SIGNUP_FIRST_NAME_ENTERED,
                                    values.first_name,
                                    errors.first_name,
                                  );
                                  handleBlur(e);
                                }}
                              />
                              <ErrorMessage name="first_name" render={CustomErrorMessage} />
                            </div>
                          </div>

                          <div
                            className="mt-4"
                            data-aos="fade"
                            data-aos-offset={-300}
                            data-aos-delay={400}
                            data-aos-duration="500"
                            data-aos-easing="ease-in-out"
                            data-aos-anchor-placement="center"
                            data-aos-once="false"
                          >
                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 text__title">
                              {$translatei18n('LastName')}
                            </label>
                            <div className="mt-1">
                              <input
                                id="last_name"
                                name="last_name"
                                type="text"
                                className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                                onChange={(e) => {
                                  setFieldValue('last_name', e.target.value);
                                  setLastname(e.target.value);
                                }}
                                value={values.last_name}
                                onBlur={(e) => {
                                  handleDetailsAnalytics(
                                    EVENTS.AMPLITEUDE.ONBOARDING_GOOGLE_SIGNUP_LAST_NAME_ENTERED,
                                    values.last_name,
                                    errors.last_name,
                                  );
                                  handleBlur(e);
                                }}
                              />
                              <ErrorMessage name="last_name" render={CustomErrorMessage} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="mt-4 z-50 relative"
                          data-aos="fade"
                          data-aos-offset={-300}
                          data-aos-delay={600}
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-anchor-placement="center"
                          data-aos-once="false"
                        >
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text__title">
                            {$translatei18n('mobileNumber')}
                          </label>
                          <div className="mt-1 z-50">
                            <Field
                                id="mobile"
                                name="mobile"
                                type="tel"
                                autoComplete="mobile"
                                required
                                className="font-Poppins text-base border-0 text-gray-700 font-medium placeholder-gray-300 border-none outline-none !focus:outline-none focus:border-0 focus:ring-0 p-0 w-full:"
                                value={values.mobile}
                                setPhoneLength={setPhoneLength}
                                handleDetailsAnalytics={(value, error) => {
                                  handleDetailsAnalytics(EVENTS.AMPLITEUDE.ONBOARDING_GOOGLE_SIGNUP_PHONE_ENTERED, values?.mobile, errors.mobile, {phoneCode: phoneCode});
                                }}
                                component={MobileNumberField} 
                                setFieldValue={setFieldValue}
                                setPhoneCode={setPhoneCode}
                                setMobile={setMobile}
                              />
                              <ErrorMessage name="mobile" render={CustomErrorMessage} />
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          data-aos="fade"
                          data-aos-offset={-300}
                          data-aos-delay={600}
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-anchor-placement="center"
                          data-aos-once="false"
                        >
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text__title">
                            {$translatei18n('EmailAddress')}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              // required
                              className="rounded-xl text-base text-[#9CA3AF] bg-[#E5E7EB] cursor-not-allowed font-medium placeholder-gray-300 border-gray-300 w-full"
                              onChange={handleChange}
                              value={values.email}
                              onBlur={(e) => {
                                handleDetailsAnalytics('onboarding_email_entered', values.email, errors.email);
                                handleBlur(e);
                              }}
                              disabled
                            />
                            <ErrorMessage name="email" render={CustomErrorMessage} />
                          </div>
                        </div>
                        <div className="mobile:fixed bottom-0 left-0 right-0 px-4 pt-4 mobile:border-t border-gray-300 bg-white z-[60]">
                          <button
                            // disabled={isSubmitting}
                            type="submit"
                            className="py-2 px-5 text-14 rounded-xl text-white bg-secondayBlue transition duration-500 w-full hover:bg-indigo-700"
                          >
                            {$translatei18n('Continue')}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </LeftMainPanel>
        <RightPanel>
          <div
            style={{ background: communityData?.color }}
            className="flex flex-1 items-center justify-center transition duration-500"
          >
            <div
              className="w-2/3 bg-white rounded-3xl shadow-lg"
              data-aos="fade"
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">
                      {communityData?.attributes?.name
                        ? `${communityData?.attributes?.slug_id?.toLowerCase().replace(' ', '')}.scenes.social`
                        : 'yoururl.com'}
                    </p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <div className="bg-gray-100 w-full rounded-t-2xl h-28" />
                <div className=" border-2 rounded-b-2xl border-gray-100 pb-2">
                  <div className="ml-4 h-16 w-16 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100" />
                  <h1 className="ml-4 font-semibold tracking-tighter text-24 text-gray-900 mb-2 flex items-center gap-2">
                    {firstname || lastname ? (
                      <span>
                        {firstname} {lastname}
                      </span>
                    ) : (
                      'Your Name'
                    )}
                    <span
                      className="bg-gradient-to-br from-primaryBlue to-secondayBlue text-white px-1.5 py-0.5 rounded tracking-normal"
                      style={{ fontSize: '12px' }}
                    >
                      {$translatei18n('CommunityOwner')}
                    </span>
                  </h1>
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
}
