import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSagas from '../saga';
import reducers from './reducers';
import JSOG from 'jsog';
import createFilter from 'redux-persist-transform-filter';
// const saveSubsetFilter = createFilter('text', ['text.canSendMessage']);
// export const transformCircular = createTransform(
//   (inboundState, key) => stringify(inboundState),
//   (outboundState, key) => parse(outboundState),
// );
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'channel'],
  // transforms: [saveSubsetFilter, transformCircular],
};

const communityPersistConfig = {
  key: 'community',
  storage,
  blacklist: ['posts', 'calendarEvents', 'totalPage', 'page', 'createCommunityData'],
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers());

const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSagas);

const persistor = persistStore(store);

export { store, persistor, communityPersistConfig };
