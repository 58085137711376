import { SET_USER, SET_USER_COUNTRY_INFO } from './actionTypes';

export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: {
            user
        }
    };
};

export const setUserCountryInfo = (payload) => ({
    type: SET_USER_COUNTRY_INFO,
    payload
})