import Cal, { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';

export default function Calcom() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal.ns?.demo('ui', {
        styles: { branding: { brandColor: '#000000' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
      });
    })();
  }, []);
  return (
    <Cal
      namespace="demo"
      calLink="graphy-community/demo"
      style={{ width: '100%', height: '600px', overflow: 'scroll' }}
      config={{ layout: 'month_view' }}
    />
  );
}
