export const Constants = {
  VOICE_OTP_VERIFIED: 'voice_otp_verified',
  COMMUNITY_SETUP_COMPLETED: 'community_setup_completed',
  COMMUNITY_CREATED: 'community_created',
  VOICE_ONBOARD_START: 'voice_onboard_start',
  VOICE_ONBOARDING_COMPLETED: 'voice_onboarding_completed',
  VOICE_EMAIL_CONFIRMED: 'voice_email_confirmed',
  FIRST_PROMOTER: 'first_promoter',
  REDITUS: 'reditus',
};

export const EVENTS = {
  AMPLITEUDE: {
    ONBOARDING_MANUAL_SIGNUP_PHONE_ENTERED: 'onboarding_manual_signup_phone_entered',
    ONBOARDING_GOOGLE_SIGNUP_PHONE_ENTERED: 'onboarding_google_signup_phone_entered',
    ONBOARDING_GOOGLE_SIGNUP_FIRST_NAME_ENTERED: 'onboarding_google_signup_first_name_entered',
    ONBOARDING_GOOGLE_SIGNUP_LAST_NAME_ENTERED: 'onboarding_google_signup_last_name_entered',
    ONBOARDING_BOOK_A_DEMO_BACK_BUTTON_CLICKED: 'onboarding_book_a_demo_back_button_clicked',
    ONBOARDING_TAKE_ME_TO_MY_COMMUNITY: 'onboarding_take_me_to_my_community',
    ONBOARDING_BOOK_A_DEMO_CLICKED: 'onboarding_book_a_demo_clicked',
  },
};

export const SCHEMA_TYPE = {
  SELF_SERVE_SIGNUP: 'SIGNUP',
  SELF_SERVE_ONBOARDING: 'SELF_SERVE_ONBOARDING',
};

export const COUNTRY_CODES = {
  KR: '+82',
};
