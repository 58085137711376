import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { getActiveCommunity } from '../../selectors/CommunitySelectors';
import { imageFetch } from '../../services/preSignedAws';

export function CommunityFavicon() {
  const activeCommunity = useSelector((state) => state?.community?.publicCommunityDetails);
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{activeCommunity?.name}</title>
        <link rel="icon" href={activeCommunity?.icon && imageFetch(activeCommunity.icon, {}, false)} />
        {activeCommunity?.custom_css && <style>{activeCommunity?.custom_css}</style>}
      </Helmet>
    </HelmetProvider>
  );
}

export function PublicCommunityFavicon() {
  const activeCommunity = useSelector((state) => state?.community?.publicCommunityDetails);
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{activeCommunity?.name}</title>
        <link rel="icon" href={activeCommunity?.icon && imageFetch(activeCommunity.icon, {}, false)} />
      </Helmet>
    </HelmetProvider>
  );
}
