import React from 'react';
import ProgressBar from '../../components/common/progressBar/Index';
import useWindowSize from '../../hooks/useWindowSize';
import Drawer from '../../components/drawer/Drawer';
import { MOBILE_SIZE } from '../../constants';

const LeftMainPanel = ({ children }) => {
  const { width, height } = useWindowSize();
  
  const desktopView = (
    <div className="px-8 xs:px-4 pb-4 w-full lg:w-36% 2xl:w-36%  flex flex-col" style={{
      overflow: 'auto'
    }}>
      <div className='sticky top-0 bg-white pt-8 pb-2' style={{ zIndex: 99999 }}>
        <ProgressBar />
      </div>
      {children}
    </div>
  )

  return (
    <>
      {(width < MOBILE_SIZE) ? <Drawer>{children}</Drawer> : desktopView }
    </>
  );
};

export default LeftMainPanel;
