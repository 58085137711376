export default function LoadingSpinner() {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="min-w-full min-h-full flex justify-center items-center absolute top-0 left-0 bg-white z-10 overflow-y-auto">
        <div className="h-full w-full flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
          <div
            className="
                    animate-spin
                    rounded-full
                    h-12
                    w-12
                    border-t-2 border-b-2 border-blue-700
                "
          ></div>
        </div>
      </div>
    </div>
  );
}
