import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import graphyLogo from '../../../assets/images/icons/graphy_brand_logo.svg';
import graphyLogoShort from '../../../assets/images/icons/graphy_brand_logo_short.svg';
import { useLocation } from 'react-router-dom';

const ProgressBar = () => {
  const progress = useSelector((state) => state.ProgressBarReducer.progress);
  const [width, setWidth] = useState(progress);
  const location = useLocation();

  useEffect(() => {
    setWidth(progress);
  }, [progress]);

  return (
    <div className="items-center block xs:flex sm:flex">
      {['/admin/login', '/admin/forgot-password', '/admin/new-password'].includes(location?.pathname) ? (
        <div className="items-center flex">
          <img src={graphyLogo} alt="Graphy Community Platform Logo" className="h-5" />
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <img src={graphyLogoShort} alt="Graphy Community Platform Logo" className="h-5 mr-2 md:hidden" />
            <img src={graphyLogo} alt="Graphy Community Platform Logo" className="h-5 hidden md:inline-block" />
          </div>
          <div className="bg-gray-200 rounded-full mt-2 xs:mt-0 sm:mt-0 overflow-hidden w-full">
            <div
              className="bg-primaryBlue h-1.5 rounded-full"
              style={{ width: `${width}%`, transitionDuration: '1s', transitionTimingFunction: 'ease-out' }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProgressBar;
