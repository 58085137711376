import React, { useCallback, useEffect, useRef, useState } from 'react';
import useCountryInfo from '../../hooks/useCountryInfo';
import { useSelector } from 'react-redux';
import { getCountryInfo } from '../../constants/utils';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { COUNTRY_CODES } from '../../constants/Constants';

function MobileNumberField({
  field,
  form,
  className,
  handleDetailsAnalytics,
  setFieldValue,
  setPhoneCode,
  setMobile,
  setPhoneLength,
}) {
  const inputRef = useRef(null);
  useCountryInfo();
  const countryOptions = getCountryInfo();
  const [mainWrapperClass, setMainWrapperClass] = useState('');
  const currentCountryCode = useSelector((state) => state?.User?.userPhoneCountryCode);
  const currentCountryFlag = useSelector((state) => state?.User?.countryFlag);
  const phoneLength = useSelector((state) => state?.User?.countryPhoneLength);

  const [selected, setSelected] = useState(currentCountryCode);
  const [selectedFlag, setSelectedFlag] = useState(currentCountryFlag);
  const [selectedPhoneLength, setSelectedPhoneLength] = useState(phoneLength);
  const [codeVal, setCodeVal] = useState(currentCountryCode);
  const [countrySelection, setCountrySelection] = useState(countryOptions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (currentCountryCode) {
      setSelectedFlag(currentCountryFlag);
      setCodeVal(currentCountryCode);
      setSelectedPhoneLength(phoneLength);
    }
  }, [currentCountryCode]);

  const handleSelect = (code) => {
    const phoneCode = code.secondary;
    const phoneLength = code.phoneLength;
    if (selected != phoneCode) {
      setMobile('');
    }
    setSelected(code.secondary);
    setSelectedPhoneLength(phoneLength);
    setSelectedFlag(code.flag);
    setFieldValue('country_code', phoneCode);
    setFieldValue('phone_length', phoneLength);
    setCodeVal(code.secondary);
    setPhoneCode(code.secondary);
    setPhoneLength(phoneLength);
  };

  const handleMobileNumberChange = (e, setFieldValue) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setFieldValue('mobile', numericValue);
    setMobile(numericValue);
  };

  const fieldView = (
    <input
      {...field}
      className={`${className} overflow-auto text-base w-full`}
      maxLength={codeVal === COUNTRY_CODES.KR ? 11 : selectedPhoneLength}
      minLength={codeVal === COUNTRY_CODES.KR ? 10 : selectedPhoneLength}
      onChange={(e) => handleMobileNumberChange(e, setFieldValue)}
      placeholder={$translatei18n('EnterYourMobileNumber')}
      onFocus={() => setMainWrapperClass('outline outline-2 outline-[#2563eb]')}
      onBlur={(e) => {
        setMainWrapperClass('');
        if (handleDetailsAnalytics) {
          handleDetailsAnalytics(form.values.mobile, form.errors.mobile);
        }
        field.onBlur(e);
      }}
    />
  );

  const handleOptions = () => {
    setIsDropdownOpen(false);
    setCountrySelection(countryOptions);
  };

  const listBoxView = () => {
    if (Object.keys(countrySelection)?.length === 0) {
      return (
        <Listbox.Option key={`Not found`} value={'Not found'}>
          <div className="flex gap-1 items-center justify-center" onClick={() => handleOptions()}>
            {/* <img className='w-[20px] h-[15px] ml-4' src={countrySelection[key]?.flag} /> */}
            <span className="text-sm font-Inter">Not found</span>
          </div>
        </Listbox.Option>
      );
    } else
      return Object.keys(countrySelection).map((key, index) => {
        return (
          <Listbox.Option key={`${countrySelection[key]?.secondary}_${index}`} value={countrySelection[key]}>
            <div className="flex gap-1 items-center" onClick={() => handleOptions()}>
              <img className="w-[20px] h-[15px] ml-3" src={countrySelection[key]?.flag} />
              <span className="text-sm font-Inter">{countrySelection[key]?.secondary}</span>
            </div>
          </Listbox.Option>
        );
      });
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    setCodeVal(val);
    setPhoneCode(val);
    const countryOptionNew = {};
    Object.keys(countryOptions).map((country) => {
      if (countryOptions[country]?.secondary === val) {
        countryOptionNew[country] = countryOptions[country];
        setSelectedFlag(countryOptions[country]?.flag);
        setMobile('');
      } else if (countryOptions[country]?.secondary?.includes(val)) {
        countryOptionNew[country] = countryOptions[country];
        setSelectedFlag('');
      }
    });

    setCountrySelection(countryOptionNew);
    setIsDropdownOpen(true);
  };

  const handleCodeFocus = (e) => {
    setIsDropdownOpen(true);
    setMainWrapperClass('outline outline-2 outline-[#2563eb]');
  };

  const handleButton = () => {
    setIsDropdownOpen(true);
  };

  return (
    <div className="flex flex-col gap-2 items-start">
      <div
        className={`relative w-full flex py-0 px-0 items-center justify-between gap-3 border border-[#E5E7EB] rounded-xl cursor-pointer ${mainWrapperClass}`}
      >
        <div className="flex items-center gap-2">
          <Listbox value={selected} onChange={(e) => handleSelect(e)}>
            <div className="flex items-center h-10 max-w-[120px]">
              <Listbox.Button
                className="flex items-center gap-1 p-1 focus:ring-0 focus:outline-none focus:border-none"
                onClick={handleButton}
              >
                {selectedFlag ? (
                  <img className="w-[20px] h-[15px] ml-2" src={selectedFlag} />
                ) : (
                  <span className="w-[20px] h-[15px] ml-2 border border-gray-500"></span>
                )}
                <div className="flex items-center gap-1">
                  <input
                    type="text"
                    placeholder="Select"
                    value={codeVal}
                    onChange={handleInputChange}
                    onFocus={(e) => handleCodeFocus(e)}
                    onBlur={() => setMainWrapperClass('')}
                    required
                    ref={inputRef}
                    className="w-[45px] px-0 border-none focus:ring-0 focus:outline-none text-base font-Inter"
                  />
                  <ChevronDownIcon className="w-5 h-5 fill-[#374151]" />
                </div>
              </Listbox.Button>
            </div>
            {isDropdownOpen && (
              <Listbox.Options
                onFocus={() => {
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
                className="shadow-md min-h-[50px] max-h-[300px] w-[120px] flex flex-col gap-2 overflow-auto absolute top-10 left-0 z-50 pt-4 bg-white"
              >
                {listBoxView()}
              </Listbox.Options>
            )}
          </Listbox>
        </div>

        <div className="flex flex-col justify-center items-start gap-[10px] flex-1 overflow-hidden">{fieldView}</div>
      </div>
    </div>
  );
}
export default MobileNumberField;
