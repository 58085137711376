import { LockClosedIcon, XIcon } from '@heroicons/react/solid';
import { ErrorMessage, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { forgotPasswordSchema } from '../../schema/loginSchema';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import graphyLogo from '../../assets/images/icons/graphy_brand_logo.svg';
import icon1 from '../../assets/images/logo/unext.png';
import icon2 from '../../assets/images/logo/cloudnine-hospital-logo.png';
import icon3 from '../../assets/images/logo/motilal.png';
import { adminResetPassword } from '../../services/authServices';
import NotificationService from '../../services/notificationService';
import { parseAxiosErrorMessage } from '../../constants/utils';

const AdminForgotPassword = () => {
  const history = useHistory();

  const forgotPasswordClicked = sessionStorage.getItem('forgotPasswordClicked');

  useEffect(() => {
    if (!forgotPasswordClicked) {
      history.replace('/admin/login');
    }
  });

  const handleAdminForgotPassword = async (values, actions) => {
    actions.setSubmitting(true);
    let valueObj = {
      email: values?.email,
    };
    try {
      const response = await adminResetPassword(valueObj);
      const { status } = response;
      if (status >= 200 && status < 300) {
        history.push('/admin/new-password', { email: values?.email });
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(parseAxiosErrorMessage(err) || 'You already have a community, please login');
        history.push('/admin/login');
      } else {
        console.log(err);
        NotificationService.error(parseAxiosErrorMessage(err) || 'Something Went Wrong');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const forgetPasswordView = (
    <>
      <div className="items-center hidden lg:flex">
        <img src={graphyLogo} alt="Graphy Community Platform Logo" className="h-5" />
      </div>
      <div className="flex-1 flex flex-col justify-center lg:flex-none">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <h2 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
              {$translatei18n('ForgotPassword')}
            </h2>
            <p className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
              {$translatei18n('DontWorryWellSendYourResetCode')}
            </p>
          </div>

          <div className="mt-4">
            <div className="mt-6">
              <Formik
                initialValues={{ email: '' }}
                validationSchema={forgotPasswordSchema}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  actions.validateField('email');
                  sessionStorage.removeItem('forgotPasswordClicked');
                  handleAdminForgotPassword(values, actions);
                }}
              >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form className="" onSubmit={handleSubmit}>
                    <div
                      className="mb-4"
                      data-aos={'fade'}
                      data-aos-delay={200}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                    >
                      <label htmlFor="email-address" className="block text-sm font-medium text__title mb-1">
                        {$translatei18n('EmailAddress')}
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                        placeholder="Email address"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="email" render={CustomErrorMessage} />
                    </div>
                    <div className="flex items-center justify-center gap-0.5">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            d="M6.66667 12.6673L2 8.00065M2 8.00065L6.66667 3.33398M2 8.00065L14 8.00065"
                            stroke="#325ED6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <button
                        className="text-sm font-Inter text-blue-700 justify-center my-4"
                        onClick={() => handleBack()}
                      >
                        {$translatei18n('BackToLogin')}
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 bg-secondayBlue hover:bg-indigo-700 text-white"
                      >
                        {$translatei18n('SendVerificationCode')}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>

          <div className="mt-8 mb-4 relative">
            <div className="relative flex justify-center text-sm">
              <span className="px-2 text__description">
                {$translatei18n('DontHaveAnAccount')}
                <button
                  type="button"
                  className="text-secondayBlue ml-1 cursor-pointer font-semibold"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  {$translatei18n('SignUp')}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <MainLayout>
      <div className="min-h-full flex w-full">
        <div
          className="m-8 w-full lg:w-1/3 2xl:w-1/3 flex flex-col px-1"
          style={{
            overflow: 'auto',
          }}
        >
          {forgetPasswordView}
        </div>
        <div className="lg:hidden">
          <LeftMainPanel>{forgetPasswordView}</LeftMainPanel>
        </div>
        <RightPanel>
          <div className="flex flex-1 items-center justify-center transition duration-500">
            <div
              className="w-5/6 bg-white rounded-3xl shadow-lg"
              data-aos={'fade'}
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">your-community-url.com</p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <h1 className="text-center font-bold tracking-tighter text-3xl text-gray-900 mb-2">
                  {$translatei18n('YoureInGoodCompany')}
                </h1>
                <p className="text-center text-20 text-gray-800">
                  {$translatei18n('MoreThan2000CreatorsAndBusinessesTrustScenesWithTheirCommunity')}
                </p>
              </div>
              <div className="flex flex-wrap justify-center gap-3 px-12 my-12">
                <div className="flex items-center justify-evenly w-full">
                  <img src={icon1} className="h-28" />
                  <img src={icon2} className="h-16" />
                </div>
                <div className="w-full">
                  <img src={icon3} className="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
};

export default AdminForgotPassword;
