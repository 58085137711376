import { FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST, SET_COMMUNITY_CREATION_DETAILS, COMMUNITY_LIST_REQUEST } from './actionTypes';

export const fetchPublicCommunityDetails = (payload) => {
  return { type: FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST, payload };
};

export const setCommunityCreationDetails = (data) => {
  return { type: SET_COMMUNITY_CREATION_DETAILS, payload: data };
};

export const setCommunityListing = (payload) => {
  return {
    type: COMMUNITY_LIST_REQUEST,
    payload,
  };
};