import { getCountryInfo } from "../../constants/utils";
import {
  SET_USER,
  AUTH_LOG_OUT,
  SET_USER_COUNTRY_INFO
} from "../actions/actionTypes";

const initialState = {
  userCountry: "",
  userPhoneCountryCode: "",
  countryPhoneLength: "",
  countryFlag: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload.user }
    case AUTH_LOG_OUT:
      return initialState
    case SET_USER_COUNTRY_INFO:
      const phoneCountryCode = getCountryInfo()
      const countryCode = action.payload?.toUpperCase()
      const phoneCode = phoneCountryCode[countryCode]?.secondary || ''
      const phoneLength = phoneCountryCode[countryCode]?.phoneLength
      const countryFlag = phoneCountryCode[countryCode]?.flag
      return {...state, userCountry: countryCode, userPhoneCountryCode: phoneCode, countryPhoneLength: phoneLength, countryFlag: countryFlag}
    default:
      return state;
  }
};

export default reducer;
