import { LockClosedIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { createVaidationSchema } from '../../schema/registerSchema';
import { authLogout, setAdminSignUp } from '../../store/actions/authActions';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import { setProgressBar } from '../../store/actions/progressBar';
import { adminSignUp, googleSignInAdminRequest } from '../../services/authServices';
import NotificationService from '../../services/notificationService';
import { deleteAuthCookie, setAuthCookie } from '../../constants/authUtils';
import { setCommunityCreationDetails } from '../../store/actions/communityActions';
import { analyticsDefaults, handleDetailsAnalytics, parseAxiosErrorMessage } from '../../constants/utils';
import AnalyticsService from '../../services/AnalyticsService';
import PasswordComponent from '../login/PasswordComponent';
import { communityRedirect } from '../../services/communityService';
import GoogleLoginIcon from '../../components/icons/GoogleLoginIcon';
import { Constants, EVENTS, SCHEMA_TYPE } from '../../constants/Constants';
import Moengage from '@moengage/web-sdk';
import MobileNumberField from '../../components/formField/MobileField';
import { ROUTE_PATH } from '../../constants';

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneLength, setPhoneLength] = useState('');
  const communityData = useSelector((state) => state?.community?.createCommunityData);
  const selectedCommunity = JSON.parse(sessionStorage.getItem('selected_category'));
  const currentPhoneLength = useSelector((state) => state?.User?.countryPhoneLength);
  const userPhoneCountryCode = useSelector((state) => state?.User?.userPhoneCountryCode);

  const localCommunityData = JSON.parse(sessionStorage.getItem('createCommunityData'));

  useEffect(() => {
    if (currentPhoneLength) {
      setPhoneLength(currentPhoneLength);
      setPhoneCode(userPhoneCountryCode);
    }
  }, [currentPhoneLength]);

  const moengageOptions = {
    current_url: window.location.href,
    screen_width: window.innerWidth,
    screen_height: window.innerHeight,
    step: 3,
    screen: 'user_details',
    community_name: localCommunityData?.name,
    community_description: localCommunityData?.description,
    community_domain: localCommunityData?.slug_id,
    community_type: selectedCommunity?.name,
  };

  useEffect(() => {
    dispatch(setProgressBar(60));
    if (!localCommunityData?.category_id) {
      history.replace('/');
    }
  }, []);

  const handleAdminSignUp = async (values, actions) => {
    actions.setSubmitting(true);

    // used to prefill the data in the community redirect book a call form
    sessionStorage.setItem(
      'prefill-data',
      JSON.stringify({
        email: values?.email,
        phone_number: values?.mobile,
        phone_country_code: values?.country_code,
        first_name: values?.first_name,
        last_name: values?.last_name,
      }),
    );

    const valueObj = {
      user: {
        email: values?.email,
        password: values?.password,
        first_name: values?.first_name,
        last_name: values?.last_name,
      },
      community: {
        name: localCommunityData?.name,
        description: localCommunityData?.description,
        category_id: localCommunityData?.category_id,
        slug_id: localCommunityData?.slug_id,
        referral: {},
      },
      contact: {
        phone_number: values?.mobile,
        phone_country_code: values?.country_code,
      },
    };

    const firstPromoterProps = JSON.parse(sessionStorage.getItem(Constants.FIRST_PROMOTER));
    if (firstPromoterProps?.fpr && firstPromoterProps?.tid) {
      valueObj.community.referral = { ...firstPromoterProps };
    }

    const reditusProps = JSON.parse(sessionStorage.getItem(Constants.REDITUS))
    if (reditusProps?.pk && reditusProps?.uid && reditusProps?.gr_id) {
      valueObj.community.referral = { ...reditusProps };
    }

    Moengage.track_event('onboarding_next_tapped', {
      ...moengageOptions,
      user_first_name: values?.first_name,
      user_last_name: values?.last_name,
      user_email: values?.email,
    });

    Moengage.add_first_name(values?.first_name);
    Moengage.add_last_name(values?.last_name);
    Moengage.add_email(values?.email);
    Moengage.add_user_attribute('subdomain', localCommunityData?.slug_id);
    Moengage.add_user_attribute('community_category', selectedCommunity?.name);
    // moengage.add_user_attribute('signup_utm_params', values?.first_name);
    // moengage.add_user_attribute('signup_website_referrer', values?.first_name);
    Moengage.add_user_attribute('current_plan', 'free_trial');

    AnalyticsService.trackAmplitudeEvent('onboarding_next_tapped', {
      ...analyticsDefaults(),
      step: 3,
      screen: 'user_details',
      user_first_name: values?.first_name,
      user_last_name: values?.last_name,
      user_email: values?.email,
      signup_method: 'email',
    });

    AnalyticsService.setAmplitudeUserProperty('first_name', values?.first_name);
    AnalyticsService.setAmplitudeUserProperty('last_name', values?.last_name);
    AnalyticsService.setAmplitudeUserProperty('email', values?.email);
    AnalyticsService.setAmplitudeUserProperty('current_plan', 'free_trial');

    try {
      const response = await adminSignUp(valueObj);
      const { status, data } = response;
      if (status >= 200 && status < 300 && data) {
        setAuthCookie(response?.data?.token, response?.data?.refresh_token?.crypted_token, response?.data?.user?.data?.id);
        dispatch(setAdminSignUp(data));

        Moengage.add_unique_user_id(response?.data?.user?.data?.id);

        sessionStorage.setItem(
          'createCommunityData',
          JSON.stringify({ ...data?.community?.data, ...localCommunityData }),
        );
        dispatch(setCommunityCreationDetails({ ...data?.community?.data, ...localCommunityData }));
        NotificationService.success('Sign-Up Success');
        const user = response?.data?.user
        const communities = response?.data?.community
        if ([Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(user?.data?.attributes?.voice_onboarding_status) && communities?.data?.attributes?.community_status === Constants.COMMUNITY_CREATED) {
          history.push("/community/appearance")
        }
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(parseAxiosErrorMessage(err) || $translatei18n("YouAlreadyHaveACommunityPleaseLogin"));
        history.push('/admin/login');
      } else {
        console.log(err?.response?.data?.error_code);
        NotificationService.error(parseAxiosErrorMessage(err) || $translatei18n("SomethingWentWrong"));
      }
    } finally {
      sessionStorage.removeItem(Constants.FIRST_PROMOTER);
      actions.setSubmitting(false);
    }
  };

  // For Google Login
  const responseGoogle = async(response) => {
    let valueObj = {
      access_token: response.tokenId,
      name: localCommunityData?.name,
      description: localCommunityData?.description,
      category_id: localCommunityData?.category_id,
      slug_id: localCommunityData?.slug_id,
      referral: {},
    };

    const firstPromoterProps = JSON.parse(sessionStorage.getItem(Constants.FIRST_PROMOTER));
    if (firstPromoterProps?.fpr && firstPromoterProps?.tid) {
      valueObj.referral = { ...firstPromoterProps };
    }

    const reditusProps = JSON.parse(sessionStorage.getItem(Constants.REDITUS))
    if (reditusProps?.pk && reditusProps?.uid && reditusProps?.gr_id) {
      valueObj.community.referral = { ...reditusProps };
    }

    try {
      const response = await googleSignInAdminRequest(valueObj)
      const { user, community:communities, token, refresh_token } = response
      if (Object.keys(communities?.data)?.length === 0) {
        NotificationService.error($translatei18n("YouDontOwnACommunityPleaseCreateOne"))
        return
      }

      Moengage.track_event('onboarding_next_tapped', {
        ...moengageOptions,
        user_first_name: user?.data?.attributes?.first_name,
        user_last_name: user?.data?.attributes?.last_name,
        user_email: user?.data?.attributes?.email,
        signup_method: 'google'
      });

      Moengage.add_first_name(user?.data?.attributes?.first_name);
      Moengage.add_last_name(user?.data?.attributes?.last_name);
      Moengage.add_email(user?.data?.attributes?.email);
      Moengage.add_user_attribute('subdomain', localCommunityData?.slug_id);
      Moengage.add_user_attribute('community_category', selectedCommunity?.name);
      // moengage.add_user_attribute('signup_utm_params', values?.first_name);
      // moengage.add_user_attribute('signup_website_referrer', values?.first_name);
      Moengage.add_user_attribute('current_plan', 'free_trial');

      // only triggered when signing up
      if (communities?.data?.attributes?.community_status !== Constants.COMMUNITY_SETUP_COMPLETED) {
        AnalyticsService.trackAmplitudeEvent('onboarding_next_tapped', {
          ...analyticsDefaults(),
          step: 3,
          screen: 'user_details',
          user_first_name: user?.data?.attributes?.first_name,
          user_last_name: user?.data?.attributes?.last_name,
          user_email: user?.data?.attributes?.email,
          signup_method: 'google',
        });
      }

      NotificationService.success($translatei18n('LoginSuccess'));
      setAuthCookie(token, refresh_token?.crypted_token, user?.data?.id);
      dispatch(setAdminSignUp(response));

      Moengage.add_unique_user_id(user?.data?.id);

      sessionStorage.setItem('createCommunityData', JSON.stringify(communities?.data));
      dispatch(setCommunityCreationDetails(communities?.data));

      if (user?.data?.attributes?.voice_onboarding_status === Constants.VOICE_EMAIL_CONFIRMED) {
        sessionStorage.setItem(
          'userData',
          JSON.stringify({
            fname: user?.data?.attributes?.first_name,
            lname: user?.data?.attributes?.last_name,
            email: user?.data?.attributes?.email,
          }),
        );

        history.push(ROUTE_PATH.ONBOARDING_PROFILE_INFO, {
          fname: user?.data?.attributes?.first_name,
          lname: user?.data?.attributes?.last_name,
          email: user?.data?.attributes?.email,
        });
      } else if (
        [Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(
          user?.data?.attributes?.voice_onboarding_status,
        ) &&
        communities?.data?.attributes?.community_status === Constants.COMMUNITY_SETUP_COMPLETED
      ) {
        const response = await communityRedirect(communities?.data?.id);
        const { status } = response;
        if (status >= 200 && status < 300) {
          dispatch(authLogout())
          localStorage.clear()
          deleteAuthCookie()
          window.location.href = response?.data?.redirect_uri
        }
      }
      else if ([Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(user?.data?.attributes?.voice_onboarding_status) && communities?.data?.attributes?.community_status === Constants.COMMUNITY_CREATED) {
        history.push("/community/appearance")
      }
      else if (user?.data?.attributes?.voice_onboarding_status === Constants.VOICE_ONBOARD_START) {
        history.push({
          pathname: "/admin/register/email/verify-otp",
          state: { resendOTP: true }
        })
        // handle properly
      }
      else {
        NotificationService.warning($translatei18n("LoginSuccessRedirectionErrorContactUs"))
      }
    }
    catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(parseAxiosErrorMessage(err) || $translatei18n('YouAlreadyHaveACommunityPleaseLogin'))
        history.push("/admin/login")
      } else {
        console.log(err);
        NotificationService.error(parseAxiosErrorMessage(err) || $translatei18n("SomethingWentWrong"))
      }
    } finally {
      sessionStorage.removeItem(Constants.FIRST_PROMOTER);
    }
  };

  const failureGoogle = (error) => {
    console.error(error);
  };

  const handleTnCClick = () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_tnc_tapped', {
      ...analyticsDefaults(),
    });
    window.open('https://www.buildonscenes.com/privacy-policy', '_blank', 'noreferrer');
  };

  const handleSignInClick = () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_signin_tapped', {
      ...analyticsDefaults(),
    });
    history.push('/admin/login');
  };

  const handleGoogleSignUpClick = (gProps) => {
    AnalyticsService.trackAmplitudeEvent('onboarding_google_signup_tapped', {
      ...analyticsDefaults(),
    });
    gProps.onClick();
  };

  return (
    <MainLayout>
      <div className="min-h-full w-full flex">
        <LeftMainPanel>
          <div className="flex-1 flex justify-center g:flex-none ">
            <div className="mx-auto px-1 w-full">
              <div>
                <h2 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
                  {$translatei18n('CreateAGraphyCommunityAccount')}
                </h2>
                <p className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
                  {$translatei18n('YouReOneStepAwayFromCreatingYourCommunity')}
                </p >
              </div >
              <div className="mt-6">
                <div className="social__login">
                  <div>
                    <div className="mt-1 grid grid-cols-1 gap-2">
                      <div>
                        <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          render={(renderProps) => (
                            <a
                              onClick={() => handleGoogleSignUpClick(renderProps)}
                              disabled={renderProps.disabled}
                              className="w-full rounded-xl inline-flex justify-center p-3 btn__light text-sm font-medium cursor-pointer"
                            >
                              <span className="align-middle inline-block mr-1">
                                <GoogleLoginIcon />
                              </span>
                              <span className="font-medium align-text-bottom font-Inter text-gray-900 inline-block">{$translatei18n("ContinuewithGoogle")}</span>
                            </a>
                          )}
                          cookiePolicy="single_host_origin"
                          onSuccess={responseGoogle}
                          onFailure={failureGoogle}
                          accessType="offline"
                          prompt="consent"
                          fetchBasicProfile={false}
                          autoLoad={false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center">
                    <div className="flex-1 border-t divider" />
                    <span className="px-2 text-xs text__description">{$translatei18n("orUseEmail")}</span>
                    <div className="flex-1 border-t divider" />
                  </div>
                </div>

                <div className="mt-2 pb-32">
                  <Formik
                    initialValues={{
                      first_name: firstname || '',
                      last_name: lastname || '',
                      email: email || '',
                      password: password || '',
                      mobile: mobile || '',
                      country_code: phoneCode || '',
                    }}
                    onSubmit={(values, actions) => handleAdminSignUp(values, actions)}
                    validationSchema={createVaidationSchema({
                      phoneLength: phoneLength,
                      phoneCode: phoneCode,
                      type: SCHEMA_TYPE.SELF_SERVE_SIGNUP,
                    })}
                    enableReinitialize
                  >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                      <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="grid xs:grid-cols-1 grid-cols-2 gap-2">
                          <div
                            className="mt-4"
                            data-aos="fade"
                            data-aos-offset={-300}
                            data-aos-delay={200}
                            data-aos-duration="500"
                            data-aos-easing="ease-in-out"
                            data-aos-anchor-placement="center"
                            data-aos-once="false"
                          >
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 text__title">
                              {$translatei18n('FirstName')}
                            </label>
                            <div className="mt-1">
                              <input
                                id="first_name"
                                name="first_name"
                                type="text"
                                required
                                className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                                onChange={(e) => {
                                  setFieldValue('first_name', e.target.value);
                                  setFirstname(e.target.value);
                                }}
                                value={values.first_name}
                                onBlur={(e) => {
                                  handleDetailsAnalytics(
                                    'onboarding_first_name_entered',
                                    values.first_name,
                                    errors.first_name,
                                  );
                                  handleBlur(e);
                                }}
                              />
                              <ErrorMessage name="first_name" render={CustomErrorMessage} />
                            </div>
                          </div>

                          <div
                            className="mt-4"
                            data-aos="fade"
                            data-aos-offset={-300}
                            data-aos-delay={400}
                            data-aos-duration="500"
                            data-aos-easing="ease-in-out"
                            data-aos-anchor-placement="center"
                            data-aos-once="false"
                          >
                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 text__title">
                              {$translatei18n('LastName')}
                            </label>
                            <div className="mt-1">
                              <input
                                id="last_name"
                                name="last_name"
                                type="text"
                                required
                                className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"

                                onChange={(e) => {
                                  setFieldValue('last_name', e.target.value);
                                  setLastname(e.target.value);
                                }}
                                value={values.last_name}
                                onBlur={(e) => {
                                  handleDetailsAnalytics(
                                    'onboarding_last_name_entered',
                                    values.last_name,
                                    errors.last_name,
                                  );
                                  handleBlur(e);
                                }}
                              />
                              <ErrorMessage name="last_name" render={CustomErrorMessage} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          data-aos="fade"
                          data-aos-offset={-300}
                          data-aos-delay={600}
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-anchor-placement="center"
                          data-aos-once="false"
                        >
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 text__title">
                            {$translatei18n('EmailAddress')}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                              onChange={(e) => {
                                setFieldValue('email', e.target.value);
                                setEmail(e.target.value);
                              }}
                              value={values.email}
                              onBlur={(e) => {
                                handleDetailsAnalytics('onboarding_email_entered', values.email, errors.email);
                                handleBlur(e);
                              }}
                            />
                            <ErrorMessage name="email" render={CustomErrorMessage} />
                          </div>
                        </div>

                        <div
                          className="mt-4 z-50 relative"
                          data-aos="fade"
                          data-aos-offset={-300}
                          data-aos-delay={600}
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-anchor-placement="center"
                          data-aos-once="false"
                        >
                          <label htmlFor="mobile" className="block text-sm font-medium text-gray-700 text__title">
                            {$translatei18n('mobileNumber')}
                          </label>
                          <div className="mt-1 z-50">
                            <Field
                              id="mobile"
                              name="mobile"
                              type="text"
                              autoComplete="mobile"
                              required
                              className="font-Poppins text-base border-0 text-gray-700 font-medium placeholder-gray-300 border-none outline-none !focus:outline-none focus:border-0 focus:ring-0 p-0 w-full:"
                              value={values.mobile}
                              component={MobileNumberField}
                              setFieldValue={setFieldValue}
                              setPhoneCode={setPhoneCode}
                              setMobile={setMobile}
                              setPhoneLength={setPhoneLength}
                              handleDetailsAnalytics={(value, error) => {
                                handleDetailsAnalytics(
                                  EVENTS.AMPLITEUDE.ONBOARDING_MANUAL_SIGNUP_PHONE_ENTERED,
                                  values?.mobile,
                                  errors.mobile,
                                  { phoneCode: phoneCode },
                                );
                              }}
                            />
                            <ErrorMessage name="mobile" render={CustomErrorMessage} />
                          </div>
                        </div>

                        <div
                          className="mt-4 z-10 relative"
                          data-aos="fade"
                          data-aos-offset={-300}
                          data-aos-delay={800}
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-anchor-placement="center"
                          data-aos-once="false"
                        >
                          <label htmlFor="password" className="block text-sm font-medium text-gray-700 text__title">
                            {$translatei18n('Password')}
                          </label>
                          <div className="mt-1">

                            <Field
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                              value={values.password}
                              setFieldValue={setFieldValue}
                              setPassword={setPassword}
                              handleDetailsAnalytics={(value, error) => {
                                handleDetailsAnalytics('onboarding_password_entered', value, error);
                              }}
                              component={PasswordComponent}
                            />
                            <ErrorMessage name="password" render={CustomErrorMessage} />
                          </div>
                        </div>

                        <div className="mobile:fixed bottom-0 left-0 right-0 px-4 pt-4 mobile:border-t border-gray-300 bg-white z-[60]">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="py-2 px-5 text-14 rounded-xl text-white bg-secondayBlue transition duration-500 w-full hover:bg-indigo-700"
                          >
                            {$translatei18n('StartYourFree14DayTrial')}
                          </button>
                          <p className="text-xs mt-2 font-regular text-gray-700 text-center">
                            {$translatei18n('ByCreatingAnAccountYouAgreeToOur')}
                            <span className="ml-1 text-gray-700 cursor-pointer underline" onClick={handleTnCClick}>
                              {$translatei18n('TermsOfService')}
                            </span>
                          </p>
                          <div className="flex justify-center items-center pt-3 bg-white mt-auto">
                            <div className="relative flex justify-center text-sm mt-4">
                              <span className="px-2 font-regular text-gray-700 text__description">
                                {$translatei18n('HaveAnAccountAlready')}
                                <span
                                  className="ml-1 cursor-pointer text-secondayBlue hover:underline font-bold"
                                  onClick={handleSignInClick}
                                >
                                  {$translatei18n('SignIn')}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div >
          </div >
        </LeftMainPanel >
        <RightPanel>
          <div
            style={{ background: localCommunityData?.color }}
            className="flex flex-1 items-center justify-center transition duration-500"
          >
            <div
              className="w-2/3 bg-white rounded-3xl shadow-lg"
              data-aos="fade"
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">
                      {localCommunityData?.name
                        ? `${localCommunityData?.slug_id?.toLowerCase().replace(' ', '')}.scenes.social`
                        : 'yoururl.com'}
                    </p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <div className="bg-gray-100 w-full rounded-t-2xl h-28" />
                <div className=" border-2 rounded-b-2xl border-gray-100 pb-2">
                  <div className="ml-4 h-16 w-16 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100" />
                  <h1 className="ml-4 font-semibold tracking-tighter text-24 text-gray-900 mb-2 flex items-center gap-2">
                    {firstname || lastname ? (<span>{firstname} {lastname}</span>) : 'Your Name'}
                    <span className='bg-gradient-to-br from-primaryBlue to-secondayBlue text-white px-1.5 py-0.5 rounded tracking-normal'
                      style={{ fontSize: "12px" }}
                    >
                      {$translatei18n('CommunityOwner')}
                    </span>
                  </h1>
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                  <div className="rounded-full w-11/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div >
    </MainLayout >
  );
}
