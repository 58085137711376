import React, { useState, useEffect } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

const CheckSlug = ({ domain, getSlugState, loader, slugAvaliable, slug, setSlug, edit, setEdit, blurHandler }) => {
  useEffect(() => {
    if (!edit) {
      setSlug(domain);
    }
    const delayDebounceFn = setTimeout(() => {
      getSlugState(edit ? slug : domain, false);
    }, 1000);

    if (domain?.length > 24 || domain?.length === 0) {
      clearTimeout(delayDebounceFn);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [domain, slug]);

  return (
    <>
      <div className=" mb-6">
        {edit ? (
          <div className="flex flex-col mb-6">
            <div className="flex justify-between items-center">
              <label className="text-14 text-gray-700 font-medium mb-2">Commuity URL</label>
              <button
                className="text-14 text-secondayBlue font-semibold hover:underline"
                onClick={() => {
                  getSlugState(slug, false);
                }}
                type="button"
              >
                Check Availability
              </button>
            </div>
            <div className="relative flex items-center">
              <input
                onChange={(e) => {
                  setSlug(
                    e.target.value
                      .toLowerCase()
                      ?.split(' ')
                      ?.join('')
                      .replace(/[^-.a-zA-Z0-9- ]/g, ''),
                  );
                }}
                onBlur={blurHandler}
                value={slug?.slice(0, 25)}
                type="text"
                minLength={3}
                className="w-full rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300"
                placeholder="app"
              />
              <span className="absolute right-4 z-10 text-gray-500 text-base font-Inter">.scenes.social</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between mb-2">
            <h1 className="text-14 text-gray-800 font-semibold">{domain?.slice(0, 25)}.scenes.social</h1>
            <button
              type="button"
              className="text-14 text-secondayBlue font-semibold hover:underline"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </button>
          </div>
        )}
        <p className="text-14 text-gray-600 ">
          This is a temporary URL to start setting up your community. Once you’re done with the setup, you can use it as
          is or connect your own custom domain.
        </p>
        {loader && (
          <div className="flex items-center justify-start mt-2">
            <div className="mr-2 bg-transparent rounded-full border-t-2 border-l-2 border-r-2 w-4 h-4 border-gray-700 animate-spin" />
            <h1 className="text-14 text-gray-500">Verifying URL</h1>
          </div>
        )}
        {slug?.length <= 24 && slug?.length !== 0 && slugAvaliable && !loader && (
          <div className="flex items-center mt-2">
            <div className={`w-3.5 h-3.5 p-0.5 rounded-full bg-green-500 mr-2`}>
              <CheckIcon className="text-white" />
            </div>
            <p className="text-14">This URL is available</p>
          </div>
        )}
        {(slug?.length > 24 || !slugAvaliable) && slug?.length !== 0 && !loader && (
          <div className="flex items-center mt-2">
            <div className={`w-3.5 h-3.5 p-0.5 rounded-full bg-red-500 mr-2`}>
              <XIcon className="text-white" />
            </div>
            {slug?.length > 24 ? (
              <p className="text-14">The Slug Length Cannot Exceed 25 Characters.</p>
            ) : (
              <p className="text-14">This URL is taken. Lets try something else.</p>
            )}
          </div>
        )}
        {slug?.length === 0 && (
          <div className="flex items-center mt-2">
            <div className={`w-3.5 h-3.5 p-0.5 rounded-full bg-red-500 mr-2`}>
              <XIcon className="text-white" />
            </div>
            <p className="text-14">The Slug Cannot be empty.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CheckSlug;
