import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { googleSignIn } from '../../store/actions/authActions';

export default function GoogleAuthenticate() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const hashParams = location.hash.substring(1).split('&');
    const communityUrl = hashParams.find((param) => param.startsWith('state='))?.split('=')[1];
    const token = hashParams.find((param) => param.startsWith('id_token='))?.split('=')[1];

    if (token) {
      dispatch(
        googleSignIn({
          access_token: token,
          requestHost: communityUrl?.replace('https://', ''),
          redirectUrl: null,
        }),
      );
    }
  }, [location.hash]);

  return <></>;
}
