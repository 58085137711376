import _ from 'lodash';
import { CHECK_SLUG_AVAILABILITY_REQUEST } from '../actions/actionTypes';

const initialState = {
  slugAvailable: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CHECK_SLUG_AVAILABILITY_REQUEST:
      return { ...state, slugAvailable : action?.payload };

    default:
      return state;
  }
};

export default reducer;
