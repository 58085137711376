import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAuthToken } from '../constants/authUtils';

function AdminProtectedRoute({ children }) {

  const history = useHistory()

  try {
    const authToken = getAuthToken();

    // Verification
    if (authToken) {
      return <>{children}</>;
    }
    // Redirect to login domain
    history.push("/admin/login")
    return null;
  } catch (error) {
    console.log('🚀 ~ file: ProtectedRoute.js ~ line 31 ~ ProtectedRoute ~ error', error);
    // Redirect to login domain
    history.push("/admin/login")

    return null;
  }
}

export default AdminProtectedRoute;
