import React from 'react';
import bgImage from '../../assets/images/mainLayoutBg.svg';
import { MOBILE_SIZE } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const MainLayout = ({ children }) => {
  const { width, height } = useWindowSize();
  const communityData = useSelector((state) => state?.community?.createCommunityData);
  const location = useLocation();

  if (width < MOBILE_SIZE) {
    if (location?.pathname === '/community/redirect') {
      return <div className="h-screen">{children}</div>;
    } else {
      return (
        <div className="font-Inter" style={{ backgroundColor: communityData?.color }}>
          <div
            className="bg-cover bg-center mix-blend-overlay absolute top-0 left-0 right-0 bottom-0"
            style={{ zIndex: 10, backgroundImage: `url(${bgImage})` }}
          ></div>
          <div className="flex items-center justify-center relative h-full" style={{ zIndex: 10 }}>
            <div
              className="flex w-10/12 max-w-7xl overflow-hidden h-90vh mobile:h-full mobile:w-full mobile:p-4"
              style={{ borderRadius: '16px', maxHeight: '920px', minHeight: '300px' }}
            >
              {children}
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="bg-gradient-to-b from-primaryBlue to-secondayBlue font-Inter">
        <div
          className="bg-cover bg-center mix-blend-overlay absolute top-0 left-0 right-0 bottom-0"
          style={{ zIndex: 10, backgroundImage: `url(${bgImage})` }}
        ></div>
        <div className="min-h-screen flex items-center justify-center relative" style={{ zIndex: 10 }}>
          <div
            className="bg-white flex w-10/12 xs:w-11/12 max-w-7xl overflow-hidden xs:h-80vh h-90vh"
            style={{ borderRadius: '40px', maxHeight: '920px', minHeight: '300px' }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
};

export default MainLayout;
