import { createSelector } from 'reselect';

const getCommunity = (state) => state?.community;

export const getCommunityState = createSelector([getCommunity], (community) => community);

export const getActiveCommunity = createSelector([getCommunity], (community) =>
  community?.communities.find((data) => data?.id === community?.activeCommunity),
);


export const getActiveCommunityAccentColor = createSelector(
  [getActiveCommunity],
  (community) => community?.accent_color || '#3469AF',
);

export const getActiveCommunityFontsClass = createSelector([getActiveCommunity], (community) => ({
  primaryFont: `font-${community?.appearance_metadata?.primary_font || 'Geomanist'} font-semibold`,
  secondaryFont: `font-${community?.appearance_metadata?.secondary_font || 'Geomanist'}`,
}));

export const getCustomDomainFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.custom_domain,
);

export const getRequestHostFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.request_host,
);

export const getRedirectUrlFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.redirect_url,
);

export const getSlugIdFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.slug_id,
);
