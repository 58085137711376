import { LockClosedIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { forgotPasswordVerifySchema } from '../../schema/loginSchema';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import graphyLogo from '../../assets/images/icons/graphy_brand_logo.svg';
import icon1 from '../../assets/images/logo/unext.png';
import icon2 from '../../assets/images/logo/cloudnine-hospital-logo.png';
import icon3 from '../../assets/images/logo/motilal.png';
import { adminResetPassword, adminResetPasswordVerify } from '../../services/authServices';
import NotificationService from '../../services/notificationService';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PasswordComponent from '../login/PasswordComponent';
import LeftMainPanel from '../layout/LeftMainPanel';
import AnalyticsService from '../../services/AnalyticsService';

const VerifyResetOTPAdmin = () => {
  const history = useHistory();
  const { state } = useLocation();

  const [counter, setCounter] = useState(10);
  const [resendOTP, setResendOTP] = useState(false);

  useEffect(() => {
    setCounter(10);
    if (!state?.email) {
      history.push('/admin/forgot-password');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    if (counter === 0) {
      setResendOTP(true);
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  const handleAdminForgotPassword = async (values, actions) => {
    if (values?.reset_password_token?.length !== 6) {
      actions.setFieldError('reset_password_token', 'Please enter the entire 6 digit code.');
      actions.setSubmitting(false);
      return;
    }
    actions.setSubmitting(true);
    let valueObj = {
      email: values?.email,
      reset_password_token: values?.reset_password_token,
      password: values?.password,
      confirm_password: values?.confirm_password,
    };
    try {
      const response = await adminResetPasswordVerify(valueObj);
      const { status } = response;
      if (status >= 200 && status < 300) {
        history.push('/admin/login');
        NotificationService.success('Password Reset Successfully.');
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(parseAxiosErrorMessage(err) || 'You already have a community, please login');
        history.push('/admin/login');
      } else {
        console.log(err);
        NotificationService.error(parseAxiosErrorMessage(err) || 'Something Went Wrong');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const resendEmailOtp = async () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_resend_otp_tapped', {
      ...analyticsDefaults(),
    });
    try {
      setCounter(60);
      setResendOTP(false);
      let valueObj = {
        email: state?.email,
      };
      const response = await adminResetPassword(valueObj);
      const { status } = response;
      if (status >= 200 && status < 300) {
        NotificationService.success('Code sent successfully');
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      setCounter(0);
      setResendOTP(true);
    }
  };

  const verifyResetOtpView = (
    <>
      <div className="items-center hidden lg:flex">
        <img src={graphyLogo} alt="Graphy Community Platform Logo" className="h-5" />
      </div>
      <div className="flex-1 flex flex-col justify-center lg:flex-none">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <h2 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
              {$translatei18n('SetNewPassword')}
            </h2>
            <p className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
              {$translatei18n('YourNewPasswordMustBeDifferentToPreviouslyUsedPasswords')}
            </p>
          </div>

          <div className="mt-4">
            <div className="mt-6">
              <Formik
                initialValues={{ email: state?.email, reset_password_token: '', password: '', confirm_password: '' }}
                onSubmit={(values, actions) => {
                  handleAdminForgotPassword(values, actions);
                }}
                validationSchema={forgotPasswordVerifySchema}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <form className="" onSubmit={handleSubmit}>
                    <div
                      className="mb-4"
                      data-aos={'fade'}
                      data-aos-delay={200}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                    >
                      <label htmlFor="reset_password_token" className="block text-sm font-medium text__title mb-1">
                        {$translatei18n('ResetCode')}
                      </label>

                      <OtpInput
                        id="reset_password_token"
                        name="reset_password_token"
                        containerStyle={{ display: 'flex', justifyContent: 'space-between' }}
                        inputStyle="border-gray-300 text-base card rounded-md min-w-2"
                        value={values.reset_password_token}
                        onChange={(val) => {
                          setFieldValue('reset_password_token', val);
                        }}
                        numInputs={6}
                        separator={<span>&nbsp;</span>}
                      />
                      <ErrorMessage name="reset_password_token" render={CustomErrorMessage} />
                    </div>

                    <div
                      className="mb-4"
                      data-aos={'fade'}
                      data-aos-delay={400}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                    >
                      <label htmlFor="password" className="block text-sm font-medium text__title mb-1">
                        {$translatei18n('NewPassword')}
                      </label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autocomplete="new-password"
                        required
                        className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                        placeholder="Enter New Password"
                        setFieldValue={setFieldValue}
                        value={values.password}
                        onBlur={handleBlur}
                        component={PasswordComponent}
                      />
                      <ErrorMessage name="password" render={CustomErrorMessage} />
                    </div>

                    <div
                      className="mb-4"
                      data-aos={'fade'}
                      data-aos-delay={600}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                    >
                      <label htmlFor="confirm_password" className="block text-sm font-medium text__title mb-1">
                        {$translatei18n('ConfirmPassword')}
                      </label>
                      <Field
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        autocomplete="new-password"
                        required
                        className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                        placeholder="Confirm New Password"
                        setFieldValue={setFieldValue}
                        value={values.confirm_password}
                        onBlur={handleBlur}
                        component={PasswordComponent}
                      />
                      <ErrorMessage name="confirm_password" render={CustomErrorMessage} />
                    </div>

                    <div>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 bg-secondayBlue hover:bg-indigo-700 text-white"
                      >
                        {$translatei18n('ResetPassword')}
                      </button>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 text-center">
                          {$translatei18n('DidntReceiveTheEmail')}
                          <button
                            disabled={!resendOTP}
                            onClick={resendEmailOtp}
                            type="button"
                            className={`ml-1 text-sm font-semibold text-secondayBlue ${
                              resendOTP ? 'opacity-100 cursor-pointer' : 'opacity-50 cursor-not-allowed'
                            }`}
                          >
                            {`${$translatei18n('ClickToResend')} ${resendOTP ? '' : `${counter} Seconds`}`}
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <MainLayout>
      <div className="min-h-full flex w-full">
        <div
          className="m-8 w-full lg:w-1/3 2xl:w-1/3 flex flex-col px-1"
          style={{
            overflow: 'auto',
          }}
        >
          {verifyResetOtpView}
        </div>
        <div className="lg:hidden">
          <LeftMainPanel>{verifyResetOtpView}</LeftMainPanel>
        </div>
        <RightPanel>
          <div className="flex flex-1 items-center justify-center transition duration-500">
            <div
              className="w-5/6 bg-white rounded-3xl shadow-lg"
              data-aos={'fade'}
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">your-community-url.com</p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <h1 className="text-center font-bold tracking-tighter text-3xl text-gray-900 mb-2">
                  {$translatei18n('YoureInGoodCompany')}
                </h1>
                <p className="text-center text-20 text-gray-800">
                  {$translatei18n('MoreThan2000CreatorsAndBusinessesTrustScenesWithTheirCommunity')}
                </p>
              </div>
              <div className="flex flex-wrap justify-center gap-3 px-12 my-12">
                <div className="flex items-center justify-evenly w-full">
                  <img src={icon1} className="h-28" />
                  <img src={icon2} className="h-16" />
                </div>
                <div className="w-full">
                  <img src={icon3} className="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
};

export default VerifyResetOTPAdmin;
