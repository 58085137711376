import server from '../api/server';
import serverV from '../api/serverVersionless';

export const checkForSlugAvailability = async (slugId, lock) =>
  new Promise((resolve, reject) => {
    server
      .post('/self_serve/verify_slug', {
        "slug_id": slugId, 
        "slug_lock_flag": lock,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
