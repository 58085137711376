import { useEffect } from 'react';
import { setUserCountryInfo } from '../store/actions/userActions';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const useCountryInfo = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const url = `https://ipinfo.io?token=${process.env.REACT_APP_IP_INFO_TOKEN}`
    axios.get(url)
      .then((response) => {
        dispatch(setUserCountryInfo(response?.data?.country))
      })
      .catch((error) => {
        console.error('Error fetching location data:', error);
      });
  }, []);

};

export default useCountryInfo