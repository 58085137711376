export const SUCCESS_MESSAGES = {
  LOGIN_SUCCESS: 'Login successfully',
  SIGNUP_SUCCESS: 'Signup successfully',
  EMAIL_VERIFIED: 'Email verified successfully',
  OTP_SENT_MOBILE: 'OTP sent to the mobile number',
  OTP_SENT_EMAIL: 'Code sent to the email',
  MOBILE_VERIFY: 'Mobile number verified successfully',
  ACCOUNT_CREATED: 'Account created successfully',
  POST_CREATE: 'Post created successfully',
  CHANNEL_CREATE: 'Channel created successfully',
  POST_UPDATE: 'Post updated successfully',
  POST_DELETE: 'Post deleted successfully',
  COMMENT_CREATE: 'Comment added successfully',
  COMMENT_UPDATE: 'Comment updated successfully',
  COMMENT_DELETE: 'Comment deleted successfully',
  COMMENT_REPLY_CREATE: 'Comment reply added successfully',
  CHANNEL_CATEGORY_CREATE: 'Category created successfully',
  COMMENT_REPLY_UPDATE: 'Comment reply updated successfully',
  COMMENT_REPLY_DELETE: 'Comment reply deleted successfully',
  ROLE_CREATE: 'Role created successfully',
  ROLE_UPDATE: 'Role updated successfully',
  ROLE_DELETE: 'Role deleted successfully',
  CHANNEL_UPDATE: 'Channel updated successfully',
  CHANNEL_CATEGORY_UPDATE: 'Channel Category updated successfully',
};

export const ERROR_MESSAGES = {};
