import server from '../api/server';
import serverV from '../api/serverVersionless';
import serverV4 from "../api/v4Server"

export const fetchPublicCommunityDetails = async (data) => await new Promise((resolve, reject) => {
  serverV
    .get(`/verify_community?request_host=${data.host}`)
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const checkForMaintenance = async () => new Promise((resolve, reject) => {
  server
    .get('/maintenance')
    .then((response) => {
      if (response && response.data && response.data.maintenance_mode === true) {
        resolve(true);
      } else {
        resolve(false);
      }
    })
    .catch((e) => {
      reject(e);
    });
});

export const communityAppearance = (communityId, value) => new Promise((resolve, reject) => {
  serverV
    .put(`/selfserve/communities/${communityId}`, {
      community: value
    })
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const communityRedirect = (communityId) => new Promise((resolve, reject) => {
  serverV4
    .post(`/self_serve/community_redirect`, {
      community_id: communityId
    })
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});