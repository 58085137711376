import { parseAxiosErrorMessage } from '../constants/utils';
import {
  fetchPublicCommunityDetails,
} from '../services/communityService';
import NotificationService from '../services/notificationService';
import {
  FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST,
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
} from '../store/actions/actionTypes';
import { all, fork, put, takeLatest, } from 'redux-saga/effects';

function* fetchPublicCommunityDetailsSaga() {
  yield takeLatest(FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicCommunityDetails(payload);
      console.log("res --->", response)
      yield put({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchPublicCommunityDetailsSaga),
  ]);
}
