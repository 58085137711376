import { LockClosedIcon, XIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import AuthBaseLayout from '../../components/auth/BaseLayout';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import CommunityNotFound from '../../components/shared/tailwind/CommunityNotFound';
import LoadingSpinner from '../../components/shared/tailwind/LoadingSpinner';
import { LOGIN_IFRAME_ID } from '../../constants';
import { loginSchema } from '../../schema';
import { getRequestHostFromCommunityPublicDetails } from '../../selectors/CommunitySelectors';
import { fetchPublicCommunityDetails } from '../../services/communityService';
import { imageFetch } from '../../services/preSignedAws';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import { appleSignIn, authLogout, googleSignIn, loginRequest } from '../../store/actions/authActions';
import { createIframe } from './loginUtils';
import useWindowSize from '../../hooks/useWindowSize';
import PasswordComponent from './PasswordComponent';
import AnalyticsService from '../../services/AnalyticsService';
import { analyticsDefaults } from '../../constants/utils';

const DEFAULT_COMMUNITY_ICON = 'https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg';
const DEFAULT_LOGIN_COVER =
  'https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80';

function TopBannerBrowser({ primaryColor }) {
  const [show, setShow] = useState(true);

  if (!show) return null;

  return (
    <div className="relative">
      <div
        className="hidden md:block absolute top-0 w-full z-10 btn__primary"
        style={{ backgroundColor: primaryColor }}
      >
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white text-center">
              {$translatei18n('PleaseUseGoogleChromeMozillaFirefoxForMicrosoftEdgeForTheBestExperience')}
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">{$translatei18n('Dismiss')}</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <div className="md:hidden w-full btn__primary" style={{ backgroundColor: primaryColor }}>
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white text-center">
              {$translatei18n('PleaseUseGoogleChromeMozillaFirefoxForMicrosoftEdgeForTheBestExperience')}
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">{$translatei18n('Dismiss')}</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Login() {
  const urlParams = new URLSearchParams(window.location.search);
  const request_host = urlParams.get('request_host');
  const currentUrl = window.location.origin

  if(request_host){
    window.location.replace(`https://${request_host}`)
  } else {
    window.location.replace(currentUrl)
  }
  const dispatch = useDispatch();
  const history = useHistory();
  const appearanceData = useSelector((state) => state?.community?.publicCommunityDetails);
  const requestHostFromPublicDetails = useSelector(getRequestHostFromCommunityPublicDetails);

  const [isLoading, setIsLoading] = useState(true);
  const [isDomainVerified, setIsDomainVerified] = useState(false);

  const primaryColor = appearanceData?.appearance_metadata?.primary_color || '#6366F1';
  const redirect_url = urlParams.get('redirect_url');
  const { width, height } = useWindowSize();

  const fetchPublicDetails = useCallback(async () => {
    try {
      if (request_host) {
        localStorage.setItem('request_host', request_host);
      }
      if (redirect_url) {
        localStorage.setItem('redirect_url', redirect_url);
      }
      const response = await fetchPublicCommunityDetails({ host: request_host });
      dispatch({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: { ...response?.data, request_host, redirect_url },
      });
      setIsDomainVerified(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, [request_host, redirect_url]);

  useEffect(() => {
    if (requestHostFromPublicDetails && requestHostFromPublicDetails !== request_host) {
      dispatch(authLogout());
    }

    if (request_host) {
      createIframe(LOGIN_IFRAME_ID, `https://${request_host}/getlocalstorage.html`);
      fetchPublicDetails();
    } else {
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, []);

  const responseGoogle = (response) => {
    debugger;
    console.log('🚀 ~ file: Login.js ~ line 141 ~ responseGoogle ~ response', response);
    dispatch(
      googleSignIn({
        access_token: response.tokenId,
        requestHost: request_host,
        redirectUrl: redirect_url,
      }),
    );
  };

  const handleAppleSignIn = (response) => {
    dispatch(
      appleSignIn({
        userCode: response.authorization.code,
        identityToken: response.authorization.id_token,
        requestHost: request_host,
      }),
    );
  };

  const failureGoogle = (error) => {
    console.error(error);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderBrowserSuggestionModal = () => {
    if (browserName === 'Chrome' || browserName === 'Firefox' || browserName === 'Edge') return null;
    return <TopBannerBrowser primaryColor={primaryColor} />;
  };

  const handleGoogleSignInClick = (gProps) => {
    AnalyticsService.trackAmplitudeEvent('community_login', {
      ...analyticsDefaults(),
      signin_method: 'google',
    });
    gProps.onClick();
  };

  const handleAppleSignInClick = (aProps) => {
    AnalyticsService.trackAmplitudeEvent('community_login', {
      ...analyticsDefaults(),
      signin_method: 'apple',
    });
    aProps.onClick();
  };

  const handleSignInSubmit = async (values, actions) => {
    await AnalyticsService.trackAmplitudeEvent('community_login', {
      ...analyticsDefaults(),
      signin_method: 'email',
    });
    dispatch(
      loginRequest({
        ...values,
        requestHost: request_host,
        redirectUrl: redirect_url,
      }),
    );
    actions.setSubmitting(false);
  };

  const handleEmailBlur = (email, error) => {
    if (!error) {
      AnalyticsService.trackAmplitudeEvent('community_login_email', {
        ...analyticsDefaults(),
        value: email,
      });
    }
  };

  const handleForgotPasswordClick = () => {
    AnalyticsService.trackAmplitudeEvent('community_login_forget_password', {
      screen_width: window.innerWidth,
      screen_height: window.innerHeight,
    });
    history.push(`/forgot-password?request_host=${request_host || ''}&redirect_url=${redirect_url || ''}`);
  };

  return isDomainVerified ? (
    <AuthBaseLayout>
      {/* {renderBrowserSuggestionModal()} */}
      <div className="min-h-full flex main--background" style={{ height: `${height}px` }}>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto m-auto rounded-lg"
                src={appearanceData?.icon ? imageFetch(appearanceData?.icon) : DEFAULT_COMMUNITY_ICON}
                alt="Workflow"
              />
              <h2 className="mt-6 text-3xl font-extrabold text__title text-center">
                {$translatei18n('SignInTo') + ' ' + appearanceData?.name
                  ? appearanceData?.name
                  : $translatei18n('YourAccount')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="social__login">
                <div>
                  <p className="text-sm font-medium text__title">{$translatei18n('SignInWith')}</p>
                  {/* {renderBubbleAuth()} */}
                  <div className="mt-1 grid grid-cols-2 gap-2">
                    <div>
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        scope="profile email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
                        render={(renderProps) => (
                          <button
                            type="button"
                            onClick={() => handleGoogleSignInClick(renderProps)}
                            disabled={renderProps.disabled}
                            className="w-full inline-flex justify-center py-2 btn__light rounded-md shadow-sm text-sm font-medium cursor-pointer"
                          >
                            <span className="align-middle inline-block mr-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                              </svg>
                            </span>
                            <span className="font-semibold align-text-bottom inline-block">
                              {$translatei18n('Google')}
                            </span>
                          </button>
                        )}
                        cookiePolicy="single_host_origin"
                        onSuccess={responseGoogle}
                        onFailure={failureGoogle}
                        accessType="offline"
                        prompt="consent"
                        fetchBasicProfile={false}
                        autoLoad={false}
                      />
                    </div>

                    <div className="signinwith__apple__gg">
                      <AppleSignin
                        authOptions={{
                          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                          scope: 'email name',
                          redirectURI: window.location.origin,
                          state: 'state',
                          nonce: 'nonce',
                          usePopup: true,
                        }}
                        onSuccess={handleAppleSignIn}
                        onError={(error) => console.error(error)}
                        render={(renderProps) => (
                          <button
                            type="button"
                            onClick={() => handleAppleSignInClick(renderProps)}
                            disabled={renderProps.disabled}
                            className="w-full inline-flex justify-center items-center py-2 btn__light rounded-md shadow-sm text-sm font-medium cursor-pointer"
                          >
                            <span className="align-middle inline-block mr-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                                fill="currentColor"
                              >
                                <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" />
                              </svg>
                            </span>
                            <span className="font-semibold align-middle inline-block">{$translatei18n('Apple')}</span>
                          </button>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center">
                  <div className="flex-1 border-t divider" />
                  <span className="px-2 text__description">{$translatei18n('OrContinueWith')}</span>
                  <div className="flex-1 border-t divider" />
                </div>
              </div>

              <div className="mt-6">
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={handleSignInSubmit}
                  validationSchema={loginSchema}
                  enableReinitialize
                >
                  {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                      {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                      <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                          <label htmlFor="email-address" className="sr-only">
                            {$translatei18n('EmailAddress')}
                          </label>
                          <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border input rounded-t-md focus:outline-none focus:z-10 sm:text-sm"
                            placeholder="Email address"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={(e) => {
                              handleEmailBlur(values.email);
                              handleBlur(e);
                            }}
                          />
                          <ErrorMessage name="email" render={CustomErrorMessage} />
                        </div>
                        <div>
                          <label htmlFor="password" className="sr-only">
                            {$translatei18n('Password')}
                          </label>
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border input rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                            placeholder="Password"
                            value={values.password}
                            onBlur={handleBlur}
                            component={PasswordComponent}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="password" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-gray-400 focus:ring-offset-0 focus:ring-0 focus:ring-gray-300 border-gray-300 rounded text__link"
                            style={{ color: primaryColor }}
                          />
                          <label htmlFor="remember-me" className="ml-2 block text-sm text__title">
                            {$translatei18n('RememberMe')}
                          </label>
                        </div>

                        <button type="button" className="text-sm cursor-pointer" onClick={handleForgotPasswordClick}>
                          <span className="font-semibold text__link" style={{ color: primaryColor }}>
                            {$translatei18n('ForgotYourPassword')}
                          </span>
                        </button>
                      </div>

                      <div>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          style={{ backgroundColor: primaryColor }}
                          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 bg-indigo-600 btn__primary"
                        >
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                          {$translatei18n('SignIn')}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="mt-6 relative">
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text__description">
                  {$translatei18n('DontHaveAnAccount')}
                  <button
                    type="button"
                    className="text__link ml-1 cursor-pointer font-semibold"
                    style={{ color: primaryColor }}
                    onClick={() => {
                      history.push(`/signup?request_host=${request_host}&redirect_url=${redirect_url || ''}`);
                    }}
                  >
                    {$translatei18n('SignUp')}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={
              appearanceData?.appearance_metadata?.login_cover_image
                ? imageFetch(appearanceData?.appearance_metadata?.login_cover_image)
                : DEFAULT_LOGIN_COVER
            }
            alt=""
          />
        </div>
      </div>
    </AuthBaseLayout>
  ) : (
    <CommunityNotFound />
  );
}
