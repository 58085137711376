import React, { useEffect, useState, Fragment } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
// import { changeAppearanceSchema } from '../../schema/communitySchema';
import { authLogout, changeCommunityAppearanceRequest } from '../../store/actions/authActions';
import { imageFetch } from '../../services/preSignedAws';
import usePrevious from '../../hooks/usePrevious';
import AnalyticsService from '../../services/AnalyticsService';
import ImagePicker from '../picker/ImagePicker';
import trash2Icon from '../../assets/images/icons/trash_2.svg';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import { LockClosedIcon } from '@heroicons/react/solid';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import { setProgressBar } from '../../store/actions/progressBar';
import { setISODay } from 'date-fns/esm';
import LoadingSpinner from '../../components/shared/tailwind/LoadingSpinner';
import { communityAppearance } from '../../services/communityService';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import NotificationService from '../../services/notificationService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setCommunityCreationDetails } from '../../store/actions/communityActions';
import { deleteAuthCookie } from '../../constants/authUtils';
import { set } from 'lodash';
import ImagePickerTypeTwo from '../picker/ImagePickerTypeTwo';
import CommunityCover from './CommunityCover';
import { MOBILE_SIZE } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';

const CommunityAccent = ({ color, setFieldValue, value, setColor }) => {
  const style = { background: color };

  const handleSelectColor = () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_community_color_selected', {
      ...analyticsDefaults(),
      color_hex: color,
    });
    setFieldValue('accent_color', color);
    setColor(color);
  };

  return (
    <button
      type="button"
      style={style}
      onClick={handleSelectColor}
      className="w-8 h-8 rounded-full cursor-pointer my-1.5 ml-0 mr-3 flex justify-center items-center"
    >
      <div className={`${color === value ? 'h-5 w-5 bg-no-repeat bg-cover bg-checkmark' : ''}`} />
    </button>
  );
};

const COMMUNITY_COLOR_ACCENT = [
  '#E77975',
  '#E9A13B',
  '#55B685',
  '#325ED6',
  '#6468E9',
  '#8461EE',
  '#E47AB3',
  '#4BA4E3',
  '#212936',
];

const ChangeLoginCoverImage = ({ setFieldValue, currentFile, setImageLoading, setCoverImage }) => {
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);

  const onImageCropped = (imgURL) => {
    AnalyticsService.trackAmplitudeEvent('onboarding_cover_uploaded_tapped', {
      ...analyticsDefaults(),
      link: imgURL,
    });
    setShowImagePicker(false);
    setFieldValue('login_cover_image', imgURL);
    setCoverImage(imgURL);
  };

  const onImageLoading = (isLoading) => {
    setLoading(isLoading);
    setImageLoading(isLoading);
  };

  const handleRemoveImage = () => {
    setCoverImage('');
    setFieldValue('login_cover_image', '');
  };

  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
    setShowImagePicker(true);
    e.target.value = '';
  };

  return (
    <>
      <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-900 ">
        Community Cover
      </label>
      <p className="block text-xs font-normal text-gray-400 mb-2">Give your community a personalised look</p>
      {loading ? (
        <>
          <div className="justify-center hidden sm:flex md:flex w-full">
            <div className="h-40 w-full overflow-hidden rounded-md mt-2 relative">
              <div className="h-full w-full flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
                <div
                  className="
                    animate-spin
                    rounded-full
                    h-12
                    w-12
                    border-t-2 border-b-2 border-blue-700
                "
                />
              </div>
            </div>
          </div>
          <div className="h-40 sm:hidden md:hidden overflow-hidden rounded-md w-full mt-2 relative">
            <div className="h-full w-full flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
              <div
                className="
                    animate-spin
                    rounded-full
                    h-12
                    w-12
                    border-t-2 border-b-2 border-blue-700
                "
              />
            </div>
          </div>
        </>
      ) : currentFile?.length ? (
        <>
          <div className="relative hidden sm:block md:block overflow-hidden rounded-md mt-2">
            <div className="flex justify-center overflow-hidden">
              <div
                className="bg-gray-100 w-full h-28 bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: `url(${imageFetch(currentFile, {}, true)})` }}
              />
              {/* <img src={imageFetch(currentFile, {}, true)} className="rounded-md w-full h-28 bg-no-repeat bg-cover bg-center" alt="cover-image" /> */}
            </div>
            <div className="absolute flex justify-center w-full">
              <div className="relative w-full">
                <button
                  type="button"
                  className="inline-flex items-center bg-gray-800 rounded-lg p-1.5 absolute bottom-1 right-1"
                  onClick={handleRemoveImage}
                >
                  <img src={trash2Icon} alt="edit" className="w-4 h-4" />
                  <span className="ml-1 text-sm text-white">Remove Image</span>
                </button>
              </div>
            </div>
          </div>
          <div className="relative sm:hidden md:hidden mobile:h-28">
            <div className="aspect-w-1 h-28 overflow-hidden rounded-md w-full mt-2">
              <img src={imageFetch(currentFile, {}, true)} className="block w-full mobile:h-28" alt="cover" />
            </div>
            <button
              type="button"
              className="inline-flex items-center bg-gray-800 rounded-lg p-2 lg:p-2.5 absolute bottom-2 right-2 lg:bottom-4 lg:right-4"
              onClick={handleRemoveImage}
            >
              <img src={trash2Icon} alt="edit" className="w-4 h-4 lg:w-5 md:h-5" />
              <span className="ml-1 text-sm lg:text-base text-white">Remove Image</span>
            </button>
          </div>
        </>
      ) : (
        <div className="mt-1 flex justify-center px-6 pt-3 pb-3 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-10 w-10 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600 justify-center">
              <label className="cursor-pointer relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                <input
                  type="file"
                  accept="image/*,image/heif,image/heic"
                  onChange={handleFileChange}
                  className="hidden"
                />
                Upload a file
              </label>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      )}
      <ImagePickerTypeTwo
        cropOptions={{ aspect: 4 / 1 }}
        onImageCropped={onImageCropped}
        onImageLoading={onImageLoading}
        showImagePicker={showImagePicker}
        setShowImagePicker={setShowImagePicker}
        returnRelativeImage
        isLoading={loading}
        fileData={fileData}
      />
    </>
  );
};

const ChangeLoginIconImage = ({ setFieldValue, currentFile, setIcon, setImageLoading }) => {
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);

  const onImageCropped = (imgURL) => {
    AnalyticsService.trackAmplitudeEvent('onboarding_logo_uploaded_tapped', {
      ...analyticsDefaults(),
      link: imgURL,
    });
    setShowImagePicker(false);
    setFieldValue('login_icon_image', imgURL);
    setIcon(imgURL);
  };

  const onImageLoading = (isLoading) => {
    setLoading(isLoading);
    setImageLoading(isLoading);
  };

  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
    setShowImagePicker(true);
    e.target.value = '';
  };

  return (
    <>
      <label className="block text-sm font-normal text-gray-900">Community Icon</label>
      <p className="block text-xs font-normal text-gray-400 mb-2">
        Add more flair to your space, so the your members recognize. Recommended Size is 1080x1080px
      </p>
      <div className="flex items-center">
        {loading ? (
          <>
            <div className="justify-start hidden sm:flex md:flex">
              <div className="h-12 w-12 overflow-hidden rounded-md relative">
                <div className="h-12 w-12 rounded flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
                  <span className="absolute inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                  <div
                    className="
                      animate-spin
                      rounded-full
                      h-10
                      w-10
                      border-t-2 border-b-2 border-blue-700
                  "
                  />
                </div>
              </div>
            </div>
            <div className="sm:hidden md:hidden overflow-hidden rounded-full w-12 h-12 mt-2 relative">
              <div className="h-full w-full flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
                <div
                  className="
                      animate-spin
                      rounded-full
                      h-10
                      w-10
                      border-t-2 border-b-2 border-blue-700
                  "
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex">
            <div className="flex items-center">
              {currentFile?.length ? (
                <>
                  <div className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                    <div className="flex justify-center h-12 w-12 overflow-hidden">
                      <img src={imageFetch(currentFile, {}, true)} className="h-full rounded-md" alt="cover" />
                    </div>
                    {/* <div className="absolute flex justify-center w-full">
                      <div className="relative" style={{ width: '252px' }}>
                        <button
                          type="button"
                          className="inline-flex items-center bg-gray-800 rounded-lg p-2 lg:p-2.5 absolute bottom-2 right-2"
                          onClick={() => setFieldValue('login_icon_image', '')}
                        >
                          <img src={trash2Icon} alt="edit" className="w-4 h-4 lg:w-5 md:h-5" />
                          <span className="ml-1 text-sm lg:text-base text-white">Remove Image</span>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </>
              ) : (
                <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                  <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
            </div>
          </div>
        )}
        <label
          disabled={loading}
          style={{
            cursor: loading ? 'not-allowed' : 'pointer',
            color: loading ? 'gray' : 'rgb(55, 65, 81)',
            opacity: loading ? '.7' : '1',
          }}
          className="ml-5 cursor-pointer	rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <input
            type="file"
            disabled={loading}
            accept="image/*,image/heif,image/heic"
            onChange={handleFileChange}
            className="hidden"
          />
          Upload Photo
        </label>
      </div>
      <ImagePickerTypeTwo
        cropOptions={{ aspect: 1 / 1 }}
        onImageCropped={onImageCropped}
        onImageLoading={onImageLoading}
        showImagePicker={showImagePicker}
        setShowImagePicker={setShowImagePicker}
        returnRelativeImage
        fileData={fileData}
        isLoading={loading}
      />
    </>
  );
};

export default function Appearance() {
  const dispatch = useDispatch();
  const history = useHistory();
  const updating = useSelector((state) => state?.community?.changingAppearance);
  const communityData = useSelector((state) => state.community?.createCommunityData);
  const adminUser = useSelector((state) => state?.auth?.adminUser);
  const [image, setImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [color, setColor] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { width, height } = useWindowSize();
  const [defaultColor] = useState('#325ED6');

  const localCommunityData = JSON.parse(sessionStorage.getItem('createCommunityData'));

  useEffect(() => {
    if (!localCommunityData?.id) {
      history.replace('/admin/login');
    }
  }, []);

  useEffect(() => {
    dispatch(setProgressBar(100));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    AnalyticsService.trackAmplitudeEvent('onboarding_community_color_selected', {
      ...analyticsDefaults(),
      color_hex: defaultColor,
    });
  }, []);

  const handleOnSubmit = async (values, actions) => {
    try {
      const newApperanceData = {
        icon: values?.login_icon_image || 'scenes_dev/uploads/categories/new_default_icon.png',
        cover_image: values?.login_cover_image || 'scenes_dev/uploads/categories/new_default_cover.png',
        accent_color: values?.accent_color || '#325ED6',
      };

      AnalyticsService.trackAmplitudeEvent('onboarding_finish_tapped', {
        ...analyticsDefaults(),
        icon_link: imageFetch(newApperanceData.icon, {}, true),
        cover_link: imageFetch(newApperanceData.cover_image, {}, true),
        color_hex: newApperanceData.accent_color,
      });

      sessionStorage.removeItem('selected_category');

      let response;
      if (communityData?.id) {
        response = await communityAppearance(communityData?.id, newApperanceData);
      } else {
        response = await communityAppearance(localCommunityData?.id, newApperanceData);
      }

      const { status, data } = response;
      if (status >= 200 && status < 300) {
        dispatch(
          setCommunityCreationDetails({
            ...data,
            color: data?.data?.accent_color,
          }),
        );
        history.push('/community/redirect');
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const colorStyle = {};
  if (width < MOBILE_SIZE) {
    colorStyle['opacity'] = 1;
  }

  return (
    <MainLayout>
      <div className="block lg:hidden">
        <CommunityCover
          coverImage={coverImage}
          image={image}
          color={color}
          communityData={communityData}
          type={2} // 1 -> right panel, 2-> preview on top on mobile
        />
      </div>
      <LeftMainPanel>
        <div>
          <Formik
            initialValues={{
              accent_color: defaultColor,
              login_cover_image: '',
              login_icon_image: '',
            }}
            onSubmit={handleOnSubmit}
            // validationSchema={changeAppearanceSchema}
            enableReinitialize
          >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
              <div className="flex flex-col mb-20">
                <div className="bg-white rounded-lg flex-1">
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-y-6 ">
                      <div className="sm:col-span-6">
                        <div className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
                          {$translatei18n('FinishSettingUpYourCommunity')}
                        </div>
                        <div className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
                          {$translatei18n('CreateBrandAddingLogoCover')}
                        </div>
                      </div>

                      <div
                        data-aos={'fade'}
                        data-aos-delay={200}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                      >
                        <div className="sm:col-span-6">
                          <ChangeLoginIconImage
                            setFieldValue={setFieldValue}
                            currentFile={values.login_icon_image}
                            setIcon={setImage}
                            setImageLoading={setIsLoading}
                          />
                        </div>
                        <div className="float-left">
                          <ErrorMessage name="login_icon_image" render={CustomErrorMessage} />
                        </div>
                      </div>
                      <div
                        className="sm:col-span-6"
                        data-aos={'fade'}
                        data-aos-delay={400}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                      >
                        <ChangeLoginCoverImage
                          setFieldValue={setFieldValue}
                          currentFile={values.login_cover_image}
                          setImageLoading={setIsLoading}
                          setCoverImage={setCoverImage}
                        />
                        <div className="float-left">
                          <ErrorMessage name="login_cover_image" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div
                        className="sm:col-span-6"
                        data-aos={'fade'}
                        data-aos-delay={600}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                        style={colorStyle}
                      >
                        <label htmlFor="communityAccent" className="block text-sm font-normal text-gray-900">
                          Community Accent Color
                        </label>
                        <p className="block text-xs font-normal text-gray-400">
                          Give your community a personalised look
                        </p>
                        <div className="flex justify-start w-full flex-wrap mt-2">
                          {COMMUNITY_COLOR_ACCENT.map((value, index) => (
                            <CommunityAccent
                              name="communityAccent"
                              key={index}
                              color={value}
                              value={values.accent_color}
                              setFieldValue={setFieldValue}
                              setColor={setColor}
                            />
                          ))}
                        </div>
                        <div className="float-left">
                          <ErrorMessage name="accent_color" render={CustomErrorMessage} />
                        </div>
                      </div>
                    </div>
                    <div className="mobile:fixed bottom-0 left-0 right-0 p-4 mobile:border-t border-gray-300 bg-white">
                      <button
                        type="submit"
                        disabled={isSubmitting || updating?.status || isLoading}
                        className={`w-full justify-center inline-flex items-center px-6 py-3 border border-transparent text-sm leading-4 font-semibold rounded-xl shadow-sm text-white bg-secondayBlue hover:bg-indigo-700 focus:outline-none transi duration-300 ${
                          isSubmitting || updating?.status || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {isSubmitting || updating?.status ? 'Saving' : 'Finish Setting up!'}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-auto w-full pt-8">
                  <div className="mb-1 text-center text-gray-700 text__body text-sm">
                    Currently logged in as <span className="font-bold">{adminUser?.user?.data?.attributes?.email}</span>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        AnalyticsService.trackAmplitudeEvent('onboarding_logout_tapped', {
                          ...analyticsDefaults(),
                        });
                        dispatch(authLogout());
                        deleteAuthCookie();
                        history.push('/admin/login');
                      }}
                      type="button"
                      className="px-2 rounded-md text-sm text__danger focus:outline-none focus:ring-0 focus:ring-offset-0 font-semibold"
                    >
                      Logout
                      <span className="sr-only">Close panel</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </LeftMainPanel>
      <RightPanel>
        <div
          style={{ background: communityData?.color }}
          className="flex flex-1 items-center justify-center transition duration-500"
        >
          <div
            className="w-2/3 bg-white rounded-3xl shadow-lg"
            data-aos={'fade'}
            data-aos-delay={200}
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-anchor-placement="center"
          >
            <CommunityCover
              coverImage={coverImage}
              image={image}
              color={color}
              communityData={communityData}
              type={1}
            />
          </div>
        </div>
      </RightPanel>
    </MainLayout>
  );
}
