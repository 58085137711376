import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import _ from 'lodash';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { setProgressBar } from '../../store/actions/progressBar';
import { checkForSlugAvailability } from '../../services/selfServeService';
import CheckSlug from './CheckSlug';
import { setCommunityCreationDetails } from '../../store/actions/communityActions';
import NotificationService from '../../services/notificationService';
import { validateCreateCommunitySchema } from '../../schema/community';
import AnalyticsService from '../../services/AnalyticsService';
import { analyticsDefaults } from '../../constants/utils';

function NameCommunity() {
  const history = useHistory();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.community?.createCommunityData);

  const [sidePanelnData, setSidePanelnData] = useState({});
  const [loader, setLoader] = useState(false);
  const [slugAvaliable, setSlugAvaliable] = useState(false);
  const [slug, setSlug] = useState('');
  const [edit, setEdit] = useState(false);

  const localCommunityData = JSON.parse(sessionStorage.getItem("createCommunityData"));

  useEffect(() => {
    dispatch(setProgressBar(40));
    if (!localCommunityData?.category_id) {
      history.replace('/');
    }
  }, []);

  const getSlugState = async (domain, lock) => {
    setLoader(true);
    try {
      const res = await checkForSlugAvailability(domain, lock);
      const { status } = res;
      if (status >= 200 && status < 300) {
        setSlug(domain);
        setSlugAvaliable(true);
        setLoader(false);
      }
      handleCommunityNameSlugAnalytics(true, domain);
    } catch (err) {
      setSlugAvaliable(false);
      setLoader(false);
      handleCommunityNameSlugAnalytics(false, domain);
    }
  };

  const handleNext = async () => {
    if (slug?.length > 25 || slug?.length === 0 || !slug) {
      NotificationService.error('Slug should be present and should not exceed 25 characters.');
      return;
    }
    try {
      const res = await checkForSlugAvailability(slug, true);
      const { status } = res;
      if (status >= 200 && status < 300) {
        AnalyticsService.setAmplitudeUserProperty('subdomain', `${slug}.scenes.social`);
        setSlugAvaliable(true);
        setLoader(false);

        sessionStorage.setItem("createCommunityData", JSON.stringify({
          color: localCommunityData?.color,
          category_id: localCommunityData?.category_id,
          name: sidePanelnData?.name,
          slug_id: slug,
          description: sidePanelnData?.description,
        }));

        dispatch(
          setCommunityCreationDetails({
            color: localCommunityData?.color,
            category_id: localCommunityData?.category_id,
            name: sidePanelnData?.name,
            slug_id: slug,
            description: sidePanelnData?.description,
          }),
        );
        AnalyticsService.trackAmplitudeEvent('onboarding_next_tapped', {
          ...analyticsDefaults(),
          step: 2,
          screen: 'community_details',
          community_name: sidePanelnData?.name,
          community_description: sidePanelnData?.description,
          community_domain: `${slug}.scenes.social`,
        });
        history.push('/admin/signup');
      }
    } catch (err) {
      setSlugAvaliable(false);
      setLoader(false);
    }
  };

  const handleBack = () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_back_tapped', {
      ...analyticsDefaults(),
      step: 2,
      screen: 'community_details',
    });
    history.push('/');
  };

  const handleCommunityDetailsAnalytics = (event, _value) => {
    AnalyticsService.trackAmplitudeEvent(event, {
      ...analyticsDefaults(),
      value: _value,
    });
  };

  const handleCommunityNameSlugAnalytics = (slugAvail, slugVal) => {
    if (!edit) {
      AnalyticsService.trackAmplitudeEvent('onboarding_community_name_entered', {
        ...analyticsDefaults(),
        value: slugVal,
        is_domain_available: slugAvail,
      });
    }

    AnalyticsService.trackAmplitudeEvent('onboarding_check_availability', {
      ...analyticsDefaults(),
      value: slugVal,
      is_domain_available: slugAvail,
    });
  };

  const handleURLAnalytics = () => {
    AnalyticsService.trackAmplitudeEvent('onboarding_url_entered', {
      ...analyticsDefaults(),
      value: slug,
    });
  };

  return (
    <MainLayout>
      <div className="flex flex-1">
        <LeftMainPanel>
          <h1 className="text-24 font-bold text-gray-700 mt-8 tracking-tighter leading-tight">
            {$translatei18n('name_your_comunity')}
          </h1>
          <h2 className="text-14 text-gray-600 tracking-tighter leading-tight mt-2">
            {$translatei18n('name_your_community_text')}
          </h2>
          <Formik
            initialValues={{
              name: '',
              description: '',
            }}
            validationSchema={validateCreateCommunitySchema}
            onSubmit={() => handleNext()}
            enableReinitialize
          >
            {({
              values, handleBlur, handleSubmit, setFieldValue, errors,
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-1 flex-col">
                <div className="flex flex-col justify-between">
                  <div className=" overflow-scroll my-2 rounded-mainCard">
                    <div className="pb-4 mx-0.5">
                      <div
                        className="flex flex-col mb-6"
                        data-aos="fade"
                        data-aos-offset={-300}
                        data-aos-delay={300}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                        data-aos-once="false"
                      >
                        <label className="text-14 text-gray-700 font-medium mb-2" htmlFor="community-name">
                          {$translatei18n("community_name")}
                        </label>
                        <input
                          id="community-name"
                          name="name"
                          type="text"
                          required
                          autoComplete="off"
                          className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300"
                          placeholder="Ex. Dexter’s Laboratory"
                          onChange={(e) => {
                            setEdit(false);
                            setFieldValue('name', e.target.value);
                            setSidePanelnData({ name: e.target.value, description: sidePanelnData?.description });
                            if (e.target.value.length <= 0) {
                              setSlug('');
                            }
                          }}
                          value={values.name}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="name" component={() => CustomErrorMessage(errors.name)} />
                      </div>
                      <Transition
                        show={values?.name?.length > 0}
                        enter="transition ease-out duration-1000"
                        enterFrom="transform opacity-0 scale-10"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-1000"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-10"
                      >
                        {values.name.length > 0 && (
                          <CheckSlug
                            domain={values?.name
                              ?.toLowerCase()
                              ?.split(' ')
                              ?.join('')
                              .replace(/[^-a-zA-Z0-9 ]/g, '')}
                            slug={slug
                              ?.toLowerCase()
                              ?.trim()
                              ?.split(' ')
                              ?.join('')
                              .replace(/[^-a-zA-Z0-9 ]/g, '')}
                            setSlug={setSlug}
                            getSlugState={getSlugState}
                            loader={loader}
                            setLoader={setLoader}
                            slugAvaliable={slugAvaliable}
                            setSlugAvaliable={setSlugAvaliable}
                            edit={edit}
                            blurHandler={handleURLAnalytics}
                            setEdit={(val) => {
                              setEdit(val);
                              AnalyticsService.trackAmplitudeEvent('onboarding_edit_url_tapped', {
                                ...analyticsDefaults(),
                                existing_domain: `${slug?.slice(0, 25)}.scenes.social`,
                                is_domain_available: slugAvaliable,
                              });
                            }}
                          />
                        )}
                        <ErrorMessage name="slug" component={CustomErrorMessage} />
                      </Transition>
                      <div
                        className="flex flex-col"
                        data-aos="fade"
                        data-aos-offset={-600}
                        data-aos-delay={600}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                        data-aos-once="false"
                      >
                        <label htmlFor="community-description" className="text-14 text-gray-700 font-medium mb-2">
                        {$translatei18n("CommunityDescription")}
                        </label>
                        <textarea
                          id="community-description"
                          name="description"
                          onChange={(e) => {
                            setFieldValue('description', e.target.value);
                            setSidePanelnData({ name: sidePanelnData.name, description: e.target.value });
                          }}
                          value={values.description}
                          onBlur={(e) => {
                            handleCommunityDetailsAnalytics(
                              'onboarding_community_description_entered',
                              values.description,
                            );
                            handleBlur(e);
                          }}
                          type="text"
                          className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 h-28"
                          placeholder="Ex. Community for learners who want to learn about design, sales & marketing."
                        />
                        <ErrorMessage name="description" component={() => CustomErrorMessage(errors.description)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center pt-3 bg-white mt-auto mobile:fixed bottom-0 left-0 right-0 px-4 pb-2 mobile:border-t border-gray-300">
                  <button
                    type="button"
                    className="py-1.5 px-4 rounded-xl text-gray-400 border-2 border-gray-400 transition duration-500"
                    onClick={handleBack}
                  >
                    {$translatei18n("Back")}
                  </button>
                  <button
                    disabled={!slugAvaliable}
                    className={`py-2 px-5 rounded-xl text-white bg-secondayBlue transition duration-500 ${slugAvaliable ? 'opacity-100 cursor-pointer hover:bg-indigo-700' : 'opacity-50 cursor-default'}`}
                    type='submit'
                  >
                    {$translatei18n("Next")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </LeftMainPanel>
        <RightPanel>
          <div style={{ background: localCommunityData?.color }} className="flex flex-1 items-center justify-center">
            <div
              className="w-2/3 bg-white rounded-3xl shadow-lg"
              data-aos="fade"
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 w-11/12 p-2 flex items-center justify-center my-4 mx-6 rounded-xl overflow-hidden">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">
                      {slug
                        ? `${slug?.slice(0, 25)}.scenes.social`
                        : 'your-community.scenes.social'}
                    </p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <div className="bg-gray-100 w-full rounded-t-2xl h-28" />
                <div className=" border-2 rounded-b-2xl border-gray-100 pb-2">
                  <div className="mx-auto h-20 w-20 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100" />
                  <h1 className="text-center font-bold tracking-tighter text-24 text-gray-900 mb-2">
                    {sidePanelnData?.name ? sidePanelnData?.name : 'Community-Name'}
                  </h1>
                  {sidePanelnData?.description ? <p className='text-center w-10/12 mx-auto break-all'> {sidePanelnData?.description}</p>
                    : <>
                      <div className="rounded-full w-10/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                      <div className="rounded-full w-8/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                      <div className="rounded-full w-6/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
}

export default NameCommunity;
