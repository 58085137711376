import React, { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import blankSheetCover from '../../assets/images/community_creation/blank_community_cover.png';
import { setCommunityCreationDetails } from '../../store/actions/communityActions';
import { setProgressBar } from '../../store/actions/progressBar';
import { getCategoriesList } from '../../services/authServices';
import { imageFetch } from '../../services/preSignedAws';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import NotificationService from '../../services/notificationService';
import AnalyticsService from '../../services/AnalyticsService';
import { Constants } from '../../constants/Constants';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';

function ChooseCommunityType() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [selected, setSelected] = useState(null);
  const [values, setValues] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem(Constants.FIRST_PROMOTER)) {
      sessionStorage.removeItem(Constants.FIRST_PROMOTER);
    }
    const searchParams = new URLSearchParams(location?.search);
    const fpr = searchParams.get('fpr');
    const tid = searchParams.get('tid');
    if (fpr && tid) {
      sessionStorage.setItem(Constants.FIRST_PROMOTER, JSON.stringify({ fpr, tid }));
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem(Constants.REDITUS)) {
      sessionStorage.removeItem(Constants.REDITUS);
    }
    const searchParams = new URLSearchParams(location?.search);
    const pk = searchParams.get('pk');
    const uid = searchParams.get('uid');
    const gr_id = searchParams.get('gr_id');
    if (pk && uid && gr_id) {
      sessionStorage.setItem(Constants.REDITUS, JSON.stringify({ pk, uid, gr_id }));
    }
  }, []);

  useEffect(() => {
    dispatch(setCommunityCreationDetails(null));
    dispatch({
      type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
      payload: { name: 'Graphy Community Platform - Start your Community now' },
    });
    dispatch(setProgressBar(20));
    getCategoryData();
  }, []);

  const getCategoryData = () => {
    setLoading(true);
    getCategoriesList()
      .then((response) => {
        const data = response?.data?.sort((a, b) => b?.id - a?.id);
        setCategories(data);
      })
      .catch((error) => {
        const message = parseAxiosErrorMessage(error);
        NotificationService.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextScreen = () => {
    delete values?.id;
    AnalyticsService.trackAmplitudeEvent('onboarding_next_tapped', {
      ...analyticsDefaults(),
      step: 1,
      screen: 'type_selection',
      community_type: values.name,
    });
    AnalyticsService.setAmplitudeUserProperty('community_category', values.name);
    history.push('/community/choose-name');
  };

  const handleTypeSelect = (val) => {
    const type = categories.find((_val) => _val.id === val);
    AnalyticsService.trackAmplitudeEvent('onboarding_community_type_selected', {
      ...analyticsDefaults(),
      type: type?.name,
    });
    sessionStorage.setItem('selected_category', JSON.stringify(categories.find((_val) => _val.id === val)));
    setSelected(val);
    setValues(categories.find((_val) => _val.id === val));
  };

  useEffect(() => {
    if (values) {
      values.category_id = values?.id;
      sessionStorage.setItem('createCommunityData', JSON.stringify(values));
      dispatch(setCommunityCreationDetails(values));
    }
  }, [values]);

  return (
    <MainLayout>
      <div className="flex flex-1">
        <LeftMainPanel>
          <h1 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
            What is your community for?
          </h1>
          <h2 className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
            We’ll help you setup based on your community needs.
          </h2>
          <div className="flex flex-col justify-between">
            <div className="h-54vh max-h-600 xs:h-50vh overflow-scroll my-0 pb-0">
              {loading ? (
                <LoadingGrid />
              ) : (
                <RadioGroup value={selected} onChange={handleTypeSelect} className="my-4 z-10">
                  {categories.map((type, index) => (
                    <RadioGroup.Option
                      key={type.name}
                      value={type.id}
                      className="cursor-pointer"
                      data-aos="fade"
                      data-aos-offset={-((index + 1) * 300)}
                      data-aos-delay={(index + 1) * 200}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                      data-aos-once="false"
                    >
                      {({ checked }) => (
                        <div
                          className={`border rounded-xl flex items-center px-3 py-3 mt-4 justify-between cursor-pointer  ${
                            checked ? 'bg-blue-50' : 'border-gray-300 bg-transparent'
                          }`}
                        >
                          <div className="flex items-center flex-row xs:flex">
                            <img
                              className="xs:h-12 xs:w-12 h-16 w-16 rounded-xl"
                              src={imageFetch(type.image)}
                              alt={type.name}
                            />
                            <div className="px-2">
                              <h1 className="font-16 font-semibold text-gray-700">{type.name}</h1>
                              <p className="font-14 text-gray-600">{type.description}</p>
                            </div>
                          </div>
                          <div className="w-5">
                            <div
                              className={`w-4 h-4 border border-gray-300 rounded-full ${
                                checked ? 'bg-secondayBlue' : 'bg-transparent'
                              }`}
                            >
                              {checked && <CheckIcon className="text-white" />}
                            </div>
                          </div>
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
          <div className="flex justify-end items-center pt-0 bg-white mt-4">
            <button
              disabled={!selected}
              className={`py-2 px-5 rounded-xl text-white bg-secondayBlue transition duration-500 mx-auto md:mx-0 w-11/12 md:w-24 ${
                selected ? 'opacity-100 cursor-pointer hover:bg-indigo-700' : 'opacity-0 md:opacity-50 cursor-default'
              }`}
              onClick={handleNextScreen}
            >
              Next
            </button>
          </div>
        </LeftMainPanel>
        <RightPanel>
          <div style={{ background: values?.color }} className="pt-16 pl-16 flex-1 transition duration-500">
            <div
              style={{
                backgroundImage: selected ? `url(${imageFetch(values?.cover_image)})` : `url(${blankSheetCover})`,
              }}
              alt="Community Creation"
              className="flex w-full h-full bg-no-repeat bg-contain bg-right"
              data-aos="fade-left"
              data-aos-delay={1000}
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            />
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
}

export default ChooseCommunityType;

function LoadingGrid() {
  return (
    <div className="mt-4 flex gap-4 flex-col">
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </div>
  );
}

function LoadingCard() {
  return (
    <div className="border rounded-xl bg-transparent border-gray-300 animate-pulse flex gap-2 px-3 py-4 items-center">
      <div className="flex xs:flex flex-row items-center w-full gap-3">
        <div className="xs:h-12 xs:w-12 h-16 w-16 bg-gray-300 rounded-lg" />
        <div className="w-full sm:flex-1">
          <div className="w-full h-4 bg-gray-300 rounded-full" />
          <div className="w-full h-2 bg-gray-300 mt-4 rounded-full" />
          <div className="w-1/2 h-2 bg-gray-300 mt-2 rounded-full" />
        </div>
      </div>
      <div className="w-4 h-4 border-2 border-gray-300 rounded-full" />
    </div>
  );
}
