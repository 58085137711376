import React, { useEffect } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AuthBaseLayout from '../../components/auth/BaseLayout';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import NotificationService from '../../services/notificationService';
import { resetPassword } from '../../services/authServices';
import { parseAxiosErrorMessage } from '../../constants/utils';
import { LOGIN_IFRAME_ID } from '../../constants';
import { fetchPublicCommunityDetails } from '../../services/communityService';
import { createIframe } from './loginUtils';
import { imageFetch } from '../../services/preSignedAws';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import { getRequestHostFromCommunityPublicDetails } from '../../selectors/CommunitySelectors';
import useWindowSize from '../../hooks/useWindowSize';
import PasswordComponent from './PasswordComponent';

const DEFAULT_LOGIN_COVER =
  'https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Please enter your password'),
  cPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default function ResetPassword(props) {
  const params = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const publicCommunityDetails = useSelector((state) => state?.community?.publicCommunityDetails);

  const primaryColor = publicCommunityDetails?.appearance_metadata?.primary_color;
  const urlParams = new URLSearchParams(window.location.search);
  const request_host = urlParams.get('request_host');
  const redirect_url = urlParams.get('redirect_url');
  const requestHostFromPublicDetails = useSelector(getRequestHostFromCommunityPublicDetails);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (!params.reset_token || params.reset_token.length !== 6 || !params.email) {
      NotificationService.error('Invalid reset token found, redirecting to forgot password');
      history.replace('/forgot-password');
    }
    if (request_host) {
      getPublicCommunity(request_host);
    }
  }, []);

  const getPublicCommunity = async (host) => {
    const response = await fetchPublicCommunityDetails({ host: host });
    dispatch({
      type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
      payload: { ...response?.data, request_host, redirect_url },
    });
    createIframe(LOGIN_IFRAME_ID, `https://${request_host}/getlocalstorage.html`);
  };

  return (
    <AuthBaseLayout>
      <div className="min-h-full flex main--background" style={{ height: `${height}px` }}>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text__title text-center">{$translatei18n('SetANewPassword')}</h2>
            </div>

            <div className="mt-8">
              <div>
                <div className="mt-6 relative">
                  <div className="relative flex justify-center text-sm text-center">
                    <span className="px-2 text__description">{$translatei18n('EnterYourNewPasswordToLogin')}</span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <Formik
                  initialValues={{ password: '', cPassword: '' }}
                  onSubmit={(values, actions) => {
                    resetPassword({
                      email: params.email,
                      reset_password_token: params.reset_token,
                      password: values.password,
                      confirm_password: values.cPassword,
                      request_host: requestHostFromPublicDetails,
                    })
                      .then((res) => {
                        if (res) {
                          NotificationService.success('Successfully changed password, redirecting to login');
                          history.replace(
                            `/login?request_host=${request_host || ''}&redirect_url=${redirect_url || ''}`,
                          );
                        }
                        actions.setSubmitting(false);
                      })
                      .catch((error) => {
                        const message = parseAxiosErrorMessage(error);
                        NotificationService.error(message);
                        actions.setSubmitting(false);
                      });
                  }}
                  validationSchema={ResetPasswordSchema}
                  enableReinitialize
                >
                  {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                      {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                      <div>
                        <label htmlFor="password" className="block text-sm font-medium text__title">
                          {$translatei18n('EnterYourNewPassword')}
                        </label>
                        <div className="mt-1">
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            required
                            component={PasswordComponent}
                            placeholder="Password"
                            className="appearance-none block w-full px-3 py-2 input card rounded-md shadow-sm sm:text-sm"
                            setFieldValue={setFieldValue}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="password" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="cPassword" className="block text-sm font-medium text__title">
                          {$translatei18n('RenterYourPassword')}
                        </label>
                        <div className="mt-1">
                          <Field
                            id="cPassword"
                            name="cPassword"
                            type="Password"
                            required
                            className="appearance-none block w-full px-3 py-2 input card rounded-md shadow-sm sm:text-sm"
                            setFieldValue={setFieldValue}
                            value={values.cPassword}
                            onBlur={handleBlur}
                            component={PasswordComponent}
                            placeholder="Re-enter Password"
                          />
                          <ErrorMessage name="cPassword" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div>
                        <button
                          style={{ background: primaryColor }}
                          disabled={isSubmitting}
                          type="submit"
                          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md text-white bg-indigo-600 btn__primary"
                        >
                          {$translatei18n('ChangePassword')}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={
              publicCommunityDetails?.appearance_metadata?.login_cover_image
                ? imageFetch(publicCommunityDetails?.appearance_metadata?.login_cover_image)
                : DEFAULT_LOGIN_COVER
            }
            alt=""
          />
        </div>
        {/* <div className="fixed inset-y-0 right-5 top-5 pl-10 max-w-full flex">
          <div className="ml-3 h-7 flex items-center">
            <button
              onClick={restartSession}
              type="button"
              style={{ background: primaryColor }}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-5 w-5 text-white group-hover:text-gray-200" aria-hidden="true" />
            </button>
          </div>
        </div> */}
      </div>
    </AuthBaseLayout>
  );
}
