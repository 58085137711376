import { Route } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import AdminProtectedRoute from './AdminProtectedRoutes';

const CustomRoute = ({ component: Component, isProtected, isAdminProtected = false, ...rest }) => {

  // Return Protected Admin Routes 
  if (isAdminProtected) {
    return (
      <AdminProtectedRoute>
        <Route component={Component} {...rest} />
      </AdminProtectedRoute>
    )
  }

  // Return simple route
  if (!isProtected) {
    return <Route component={Component} {...rest} />;
  }

  // Return protected route
  return (
    <Route
      {...rest}
      render={(props) => (
        <ProtectedRoute>
          <Component {...props} />
        </ProtectedRoute>
      )}
    />
  );
};
export default CustomRoute;
