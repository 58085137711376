import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ProgressBar from '../common/progressBar/Index';
import { useLocation } from 'react-router-dom';

export default function Drawer({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const getTopOffset = () => {
    const pathStr = location?.pathname;
    const InRoute = ['/admin/login', '/community/appearance'].includes(pathStr);
    if (InRoute) {
      if (pathStr === '/community/appearance') {
        return '288px';
      } else if (pathStr === '/admin/login') {
        return '288px';
      }
    } else return '48px';
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => {}}
        as="div"
        className="fixed  m-auto left-0 right-0 bottom-0 overflow-y-auto mx-auto"
        style={{ zIndex: 99 }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed bg-black bg-opacity-0 inset-0" />
        </Transition.Child>
        <span className="inline-block h-screen align-middle bg-slate-500" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="w-full rounded-t-3xl h-[100vh]">
            <div
              className={`p-4 bg-white rounded-t-3xl fixed bottom-0 overflow-auto w-full`}
              style={{
                height: `calc(100% - ${getTopOffset()})`,
                boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.10), 0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
              }}
            >
              <ProgressBar />
              {children}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
