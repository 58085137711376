/**
 * Refresh token service to manage the subs across all the axios instances
 */
export default class RefreshTokenRequestService {
  static isRefreshTokenRequested = false;
  static subscribers = [];

  static refreshTokenRequestStarted() {
    this.isRefreshTokenRequested = true;
  }

  static refreshTokenRequestCompleted() {
    this.isRefreshTokenRequested = false;
  }

  static getRefreshTokenRequestStatus() {
    return this.isRefreshTokenRequested;
  }

  static addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  static onAccessTokenFetched(access_token) {
    this.subscribers.forEach((callback) => callback(access_token));
    this.subscribers = [];
  }
}
