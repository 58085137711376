import React, { useState } from 'react';
import EyeIcon from '../../components/icons/EyeIcon';
import EyeSlashIcon from '../../components/icons/EyeSlashIcon';

const PasswordComponent = ({
  field,
  form,
  className,
  placeholder,
  required,
  handleDetailsAnalytics,
  setPassword,
  setFieldValue,
}) => {
  const [isShow, setIsShow] = useState(false);
  const handleChange = (e) => {
    if (typeof setFieldValue === 'function') setFieldValue(field?.name, e.target.value);
    if (typeof setPassword === 'function') setPassword(e.target.value);
  };

  return (
    <div className="relative">
      <input
        id="password"
        name="password"
        type={isShow ? 'text' : 'password'}
        autoComplete="current-password"
        required={required}
        className={className}
        {...field}
        value={field.password}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => {
          if (handleDetailsAnalytics) {
            handleDetailsAnalytics(form.values.password, form.errors.password);
          }
          field.onBlur(e);
        }}
      />
      <span onClick={() => setIsShow(!isShow)} className="cursor-pointer eye-icon-align z-20">
        {isShow ? <EyeSlashIcon /> : <EyeIcon />}
      </span>
    </div>
  );
};

export default PasswordComponent;
