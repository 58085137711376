import { useSelector } from 'react-redux';
import CommunityNotFound from '../components/shared/tailwind/CommunityNotFound';
import { getRequestHostFromCommunityPublicDetails } from '../selectors/CommunitySelectors';
import { isUserLoggedIn } from '../selectors/ProfileSelectors';

const ProtectedRoute = ({ children }) => {
  const isUserLogin = useSelector(isUserLoggedIn);
  const isCommunityDetails = useSelector(getRequestHostFromCommunityPublicDetails);

  return <div>{isUserLogin && isCommunityDetails ? <>{children}</> : <CommunityNotFound />}</div>;
};

export default ProtectedRoute;
