import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import Cropper from 'react-easy-crop';
import {
  ChevronLeftIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@heroicons/react/outline';
import Slider from 'rc-slider';
import { ReactComponent as OneByOne } from '../../assets/images/icons/OneByOne.svg';
import { ReactComponent as ThreeByTwo } from '../../assets/images/icons/ThreeByTwo.svg';
import { ReactComponent as ThreeByFour } from '../../assets/images/icons/ThreeByFour.svg';
import { ReactComponent as SixteenByNine } from '../../assets/images/icons/SixteenByNine.svg';
import { getPresignedPostData, uploadFileToS3, imageFetch } from '../../services/preSignedAws';
import NotificationService from '../../services/notificationService';
import { getActiveCommunityAccentColor } from '../../selectors/CommunitySelectors';
import 'rc-slider/assets/index.css';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc,
  fileInfo,
  editWithCropper,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  if (editWithCropper === false) {
    return fileInfo;
  }
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      fileInfo && fileInfo?.type && (fileInfo?.name.includes('.heic') || fileInfo.name.includes('.HEIC'))
        ? 'image/jpeg'
        : fileInfo?.type,
    );
  });
}

function ImagePickerTypeTwo({
  onImageCropped,
  onImageLoading,
  returnRelativeImage = false,
  setShowImagePicker,
  showImagePicker,
  cropOptions,
  fileData
}) {
  const [originalImage, setOriginalImage] = useState();
  const [openImageCropModal, setOpenImageCropModal] = useState(showImagePicker);
  const [file, setFile] = useState(fileData)
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState({ label: '1:1', value: 1 / 1 });
  const [editWithCropper, setEditWithCropper] = useState(true);
  const communityData = useSelector((state) => state.community.createCommunityData);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const accentColor = useSelector(getActiveCommunityAccentColor);

  useEffect(() => {
    const checkFileData = async () => {
      if (fileData) {
        onImageLoading(true);
        const url = URL.createObjectURL(fileData);
        setOpenImageCropModal(true);
        setOriginalImage(url);
        onImageLoading(false);
        setFile(fileData)
      }
    };
    checkFileData();
  }, [fileData]);

  const handleEdit = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({ label: '1:1', value: 1 / 1 });
    setEditWithCropper(true);
  }

  const getFileSizeString = (size) => {
    let sizeString = '';
    if (size < 1000) {
      sizeString = `${size} byte`;
    } else if (size < 1000000) {
      sizeString = `${(size / 1000).toFixed(2)} KB`;
    } else {
      sizeString = `${(size / 1000000).toFixed(2)} MB`;
    }
    return sizeString;
  };
  const handleSubmit = async () => {
    onImageLoading(true);
    let imgSize = 0;
    const croppedFile = await getCroppedImg(originalImage, fileData, editWithCropper, croppedAreaPixels);

    if (croppedFile) {
      imgSize = croppedFile.size;
      if (imgSize <= 10000000) {
        onImageLoading(true);
        setOpenImageCropModal(false);
        const url = `self_serve/request_presigned_url`;
        const data = await getPresignedPostData(url, communityData?.id, "community");
        const fileObj = {
          uri: croppedFile,
          name: `IMG_${Date.now()}`,
          type: file.type,
        };
        uploadFileToS3(data, fileObj)
          .then(async (response) => {
            window.DOMParser = require('xmldom').DOMParser;
            const responseDoc = new DOMParser().parseFromString(response.data, 'application/xml');
            const imageSrc = responseDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
            if (returnRelativeImage) {
              onImageCropped(imageSrc);
            } else {
              const img = await imageFetch(imageSrc, {}, false);
              onImageCropped(img);
            }
            onImageLoading(false);
            setEditWithCropper(false);
            setOriginalImage(null);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setCroppedAreaPixels(null);
            setAspectRatio({ label: '1:1', value: 1 / 1 });
            onImageLoading(false);
          })
          .catch((e) => {
            setEditWithCropper(false);
            setOriginalImage(null);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setCroppedAreaPixels(null);
            setAspectRatio({ label: '1:1', value: 1 / 1 });
            onImageLoading(false);
          });
        setOriginalImage();
      } else {
        NotificationService.error(
          `Image size should be less than 10 mb(current size of cropped image is ${getFileSizeString(imgSize)})`,
        );
      }
    }
  };

  const handleClose = () => {
    setOriginalImage();
    setOpenImageCropModal(false);
    setEditWithCropper(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({ label: '1:1', value: 1 / 1 });
    setShowImagePicker(false);
  };

  const getFooterClassName = () => {
    let footerClassName = "flex flex-row items-center justify-between cursor-pointer h-16"
    if(cropOptions){
      footerClassName = "flex flex-row items-center justify-center cursor-pointer h-16"
    }
    return footerClassName
  }

  if (!openImageCropModal) return null;
  return (
    <>
      <Transition.Root show={openImageCropModal} as="div">
        <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={handleClose} style={{zIndex: 9999}}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 align-bottom card justify-center rounded-lg text-left overflow-hidden shadow-xl transition-all md:align-middle w-1/2 xs:w-11/12 sm:w-full">
                <div className="h-full flex flex-col shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text__title">
                        <div
                          role="none"
                          className="flex flex-row items-center cursor-pointer h-16"
                          onClick={handleClose}
                        >
                          <ChevronLeftIcon className="h-5 w-5 main__icon ltr:mr-2 rtl:ml-2" aria-hidden="true" />
                          <span className="mt-1">Crop media</span>
                        </div>
                      </Dialog.Title>
                      <div>
                        <button
                          type="button"
                          className="w-full ltr:mr-2 rtl:ml-2  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:text-sm btn__primary"
                          onClick={handleSubmit}
                          style={{ backgroundColor: accentColor }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full" style={{height: "50vh"}}>
                    <Cropper
                      image={originalImage}
                      crop={crop}
                      zoom={zoom}
                      style={{
                        containerStyle: {
                          height: '100%',
                          width: '100%',
                          background: 'rgba(0, 0, 0, 0.9)',
                        },
                      }}
                      aspect={cropOptions ? cropOptions?.aspect : aspectRatio?.value}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div role="none" className={getFooterClassName()}>
                    {
                      cropOptions ? null : (
                        <div className="p-2 w-1/2 flex justify-start ltr:ml-6 rtl:mr-6 items-center">
                          <span onClick={() => setAspectRatio({ label: '1:1', value: 1 / 1 })} className="mr-4">
                            <OneByOne
                              className="icon h-5 main__icon"
                              style={aspectRatio.label === '1:1' ? { color: accentColor } : {}}
                            />
                          </span>
                          <span onClick={() => setAspectRatio({ label: '3:2', value: 3 / 2 })} className="mr-4">
                            <ThreeByTwo
                              className="icon h-5 main__icon"
                              style={aspectRatio.label === '3:2' ? { color: accentColor } : {}}
                            />
                          </span>
                          <span onClick={() => setAspectRatio({ label: '3:4', value: 3 / 4 })} className="mr-4">
                            <ThreeByFour
                              className="icon h-5 main__icon"
                              style={aspectRatio.label === '3:4' ? { color: accentColor } : {}}
                            />
                          </span>
                          <span onClick={() => setAspectRatio({ label: '16:9', value: 16 / 9 })} className="mr-4">
                            <SixteenByNine
                              className="icon h-5 main__icon"
                              style={aspectRatio.label === '16:9' ? { color: accentColor } : {}}
                            />
                          </span>
                        </div>
                      )
                    }
                    
                    <div className="w-1/2 gap-2 flex justify-center ltr:mr-4 rtl:ml-4 items-center">
                      <ZoomOutIcon
                        className="h-6 w-6 flex-none rounded-full p-0.5 main__icon ltr:mr-2 rtl:ml-2"
                        style={{ color: accentColor }}
                        onClick={() => {
                          if (zoom <= 3 && zoom >= 1.1) {
                            setZoom((prevZoom) => prevZoom - 0.1);
                          }
                        }}
                      />
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(zoom) => {
                          setZoom(zoom);
                        }}
                        // railStyle={{ backgroundColor: accentColor }}
                        trackStyle={{ backgroundColor: accentColor }}
                        handleStyle={{
                          borderColor: accentColor,
                          backgroundColor: accentColor,
                        }}
                      />
                      <ZoomInIcon
                        className="h-6 w-6 flex-none rounded-full p-0.5 main__icon ltr:ml-2 rtl:mr-2"
                        style={{ color: accentColor }}
                        onClick={() => {
                          if (zoom <= 2.9 && zoom >= 1) {
                            setZoom((prevZoom) => prevZoom + 0.1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ImagePickerTypeTwo;