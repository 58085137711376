import React from 'react';
import { imageFetch } from '../../services/preSignedAws';
import { LockClosedIcon } from '@heroicons/react/solid';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import Truncate from 'react-truncate';
import { truncateText } from '../../helpers';

export default function CommunityCover({ coverImage, image, color, communityData, type }) {
  if (type === 1)
    return (
      <>
        <div className=" flex items-center justify-center">
          <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
            <div className="flex items-center mx-auto">
              <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
              <p className="text-center">{communityData?.attributes?.slug_id}.scenes.social</p>
            </div>
            <img src={reloadIcon} className="w-5 h-5 self-end" />
          </div>
        </div>
        <div className="border-b-2 border-gray-300" />
        <div className="p-12">
          {coverImage ? (
            <div
              className="bg-gray-100 w-full rounded-t-2xl h-28 bg-no-repeat bg-cover bg-center"
              style={{ backgroundImage: `url(${imageFetch(coverImage)})` }}
            />
          ) : (
            <div
              className="bg-gray-100 w-full rounded-t-2xl h-28"
              style={{ backgroundColor: color ? color : '#f6f6f6' }}
            />
          )}
          <div className=" border-2 rounded-b-2xl border-gray-100 pb-2">
            {image ? (
              <img
                src={imageFetch(image)}
                className="mx-auto h-20 w-20 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100"
                alt="cover"
              />
            ) : (
              <div className="mx-auto h-20 w-20 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100" />
            )}

            <h1 className="text-center font-bold tracking-tighter text-24 text-gray-900 mb-2">
              {communityData?.attributes?.name}
            </h1>
            {communityData?.attributes?.description ? (
              <p className="text-center w-10/12 mx-auto break-all">{communityData?.attributes?.description}</p>
            ) : (
              <>
                <div className="rounded-full w-10/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                <div className="rounded-full w-8/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
                <div className="rounded-full w-6/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
              </>
            )}

            <div className="flex pt-2 mx-auto">
              <button
                className="inline-flex mx-auto items-center px-6 py-3 border border-transparent text-sm leading-4 font-bold rounded-xl shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                style={{ backgroundColor: color ? color : '#325ED6' }}
              >
                Let’s Get Started
              </button>
            </div>
          </div>
        </div>
      </>
    );
  else {
    return (
      <div className="h-72 absolute top-0 w-11/12 mx-auto mt-4 bg-white left-0 right-0  rounded-t-xl">
        <div className=" flex items-center justify-center">
          <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-3 mx-3 rounded-xl">
            <div className="flex items-center mx-auto">
              <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
              <p className="text-center">{communityData?.attributes?.slug_id}.scenes.social</p>
            </div>
            <img src={reloadIcon} className="w-5 h-5 self-end" />
          </div>
        </div>
        <div className="border-b-2 border-gray-300" />

        {coverImage ? (
          <div
            className="bg-gray-100 w-full rounded-t-2xl h-12 bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: `url(${imageFetch(coverImage)})` }}
          />
        ) : (
          <div className="bg-gray-100 w-full h-12" style={{ backgroundColor: color ? color : '#325ED6' }} />
        )}
        <div className="border-gray-100 pb-2">
          {image ? (
            <img
              src={imageFetch(image)}
              className="mx-auto h-20 w-20 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100"
              alt="cover"
            />
          ) : (
            <div className="mx-auto h-20 w-20 rounded-full border-2 border-dotted border-gray-300 -mt-10 bg-gray-100" />
          )}

          <h1 className="text-center font-bold tracking-tighter text-sm font-Inter text-gray-900 mb-1">
            {communityData?.attributes?.name}
          </h1>
          {communityData?.attributes?.description ? (
            <p className="text-center text-xs font-Geomanist w-10/12 mx-auto line-clamp-3">
              {/* <Truncate lines={3} ellipsis={"..."}> */}
              {communityData?.attributes?.description}
              {/* </Truncate> */}
            </p>
          ) : (
            <>
              <div className="rounded-full w-10/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
              <div className="rounded-full w-8/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
              <div className="rounded-full w-6/12 mx-auto py-1.5 bg-gray-100 my-2.5" />
            </>
          )}

          <div className="flex pt-1 mx-auto">
            <button
              className="inline-flex mx-auto items-center px-6 py-2 border border-transparent text-xs font-Inter leading-4 font-bold rounded-xl shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              style={{ backgroundColor: color ? color : '#111827' }}
            >
              Let’s Get Started
            </button>
          </div>
        </div>
      </div>
    );
  }
}
