import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { authLogout } from '../../store/actions/authActions';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import LeftMainPanel from '../layout/LeftMainPanel';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import { confirmAdminEmail, getAdminResetPasswordCode } from '../../services/authServices';
import { setProgressBar } from '../../store/actions/progressBar';
import icon1 from '../../assets/images/logo/unext.png';
import icon2 from '../../assets/images/logo/cloudnine-hospital-logo.png';
import icon3 from '../../assets/images/logo/motilal.png'
import { deleteAuthCookie } from '../../constants/authUtils';
import NotificationService from '../../services/notificationService';
import AnalyticsService from '../../services/AnalyticsService';

export default function VerifyEmailOTP() {
  const { state } = useLocation();

  const [otp, setOtp] = useState('');
  const [resendOtp, setResendOTP] = useState(false);
  const [counter, setCounter] = useState(60);
  const dispatch = useDispatch();
  const history = useHistory();

  const communityData = useSelector((state) => state?.community?.createCommunityData);
  const adminUser = useSelector((state) => state?.auth?.adminUser);

  useEffect(() => {
    dispatch(setProgressBar(80));
    setCounter(60);
    if (state?.resendOTP) {
      resendEmailOtp();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    if (counter === 0) {
      clearInterval(interval);
      setResendOTP(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  const handleSubmit = async () => {
    if (otp.length !== 6) {
      NotificationService.error('Please Enter OTP');
      return;
    }
    AnalyticsService.trackAmplitudeEvent('onboarding_verify_your_email_tapped', {
      ...analyticsDefaults()
    });
    try {
      const response = await confirmAdminEmail({ otp, email: adminUser?.user?.data?.attributes?.email });
      const { status } = response;
      if (status >= 200 && status < 300) {
        AnalyticsService.setAmplitudeIdentity(response?.data?.user?.data?.attributes?.uuid);
        AnalyticsService.setAmplitudeUserProperty('user_id', response?.data?.user?.data?.id);
        AnalyticsService.setAmplitudeUserProperty('user_uuid', response?.data?.user?.data?.attributes?.uuid);
        NotificationService.success('Verificaiton Done');
        history.replace('/community/appearance');
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      console.log('ERRROR --> ', error);
    }
  };

  const resendEmailOtp = async () => {
    try {
      setCounter(60);
      setResendOTP(false);
      const response = await getAdminResetPasswordCode({ email: adminUser?.user?.data?.attributes?.email });
      const { status } = response;
      if (status >= 200 && status < 300) {
        NotificationService.success('Code sent successfully');
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      setCounter(0);
      setResendOTP(true);
    }
  };

  return (
    <MainLayout>
      <div className="flex w-full" style={{ overflow: 'scroll' }}>
        <LeftMainPanel>
          <div
            className="relative flex-1 flex items-center px-1"
          >
            <div className="mx-auto my-auto w-full flex flex-col h-full">
              <div className="mb-4">
                <div className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
                  {$translatei18n('LetsVerifyYourEmail')}
                </div>
                <div className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
                  {$translatei18n('WeLoveInternet&WeWantToPreventSpam')}
                </div>
              </div>
              <div className="mb-4">
                <div className="">

                  <div className="">
                    <div
                      className="mb-5"
                      data-aos="fade"
                      data-aos-delay={300}
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-anchor-placement="center"
                    >
                      <OtpInput
                        containerStyle={{ display: 'flex', justifyContent: 'space-between' }}
                        inputStyle="border-gray-300 text-base rounded-md min-w-2"
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        separator={<span>&nbsp;</span>}
                      />
                    </div>

                    <div className="">
                      <div className="mobile:fixed bottom-0 left-0 right-0 p-4 mobile:border-t border-gray-300 bg-white">
                        <button
                          type="submit"
                          className="py-2 px-5 text-14 rounded-xl text-white bg-secondayBlue transition duration-500 w-full hover:bg-indigo-700"
                          onClick={handleSubmit}
                        >
                          {$translatei18n('VerifyYourEmail')}
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 text-center">
                          {$translatei18n('DidntReceiveTheEmail')}
                          <button
                            disabled={!resendOtp}
                            onClick={resendEmailOtp}
                            className={`ml-1 text-sm font-semibold text-secondayBlue cursor-pointer ${resendOtp ? "opacity-100" : "opacity-50"}`}>
                            {$translatei18n('ClickToResend') + resendOtp ? null : ` in ${counter} Seconds`}
                          </button>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-auto w-full">
                <div className="mb-1 text-center text-gray-700 text__body text-sm">
                  {$translatei18n('CurrentlyLoggedInAs')}
                  {' '}
                  <span className="font-bold">{adminUser?.user?.data?.attributes?.email}</span>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      AnalyticsService.trackAmplitudeEvent('onboarding_logout_tapped', {
                        ...analyticsDefaults(),
                      });
                      dispatch(authLogout());
                      deleteAuthCookie();
                      history.push('/admin/login');
                    }}
                    type="button"
                    className="px-2 rounded-md text-sm text__danger focus:outline-none focus:ring-0 focus:ring-offset-0 font-semibold"
                  >
                    {$translatei18n('Logout')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LeftMainPanel>
        <RightPanel>
          <div
            style={{ background: communityData?.color }}
            className="flex flex-1 items-center justify-center transition duration-500"
          >
            <div
              className="w-5/6 bg-white rounded-3xl shadow-lg"
              data-aos="fade"
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">
                      {communityData?.slug_id}
                      .scenes.social
                    </p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <h1 className="text-center font-bold tracking-tighter text-3xl text-gray-900 mb-2">
                  {$translatei18n('YoureInGoodCompany')}
                </h1>
                <p className="text-center text-20 text-gray-800">
                  {$translatei18n('MoreThan2000CreatorsAndBusinessesTrustScenesWithTheirCommunity')}
                </p>
              </div>
              <div className='flex flex-wrap justify-center gap-3 px-12 my-12'>
                <div className='flex items-center justify-evenly w-full'>
                  <img src={icon1} className="h-28" />
                  <img src={icon2} className="h-16" />
                </div>
                <div className='w-full'>
                  <img src={icon3} className="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
}
