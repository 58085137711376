import _ from 'lodash';
import {
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
  SET_COMMUNITY_CREATION_DETAILS,
  COMMUNITY_LIST_REQUEST
} from '../actions/actionTypes';

const initialState = {
  categories: [],
  communities: [],
  hostedCommunities: [],
  joinedCommunities: [],
  publicCommunityDetails: {},
  communityCreated: false,
  activeCommunity: null,
  posts: [],
  calendarEvents: [],
  paginationData: {},
  totalPage: 1,
  page: 1,
  createCommunityData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS:
      return { ...state, publicCommunityDetails: action?.payload };

    case SET_COMMUNITY_CREATION_DETAILS:
      return { ...state, createCommunityData: action?.payload };
    case COMMUNITY_LIST_REQUEST:
      return { ...state, communityList: action?.payload };

    default:
      return state;
  }
};

export default reducer;
