import React, { useState, useEffect, Fragment } from 'react';
import MainLayout from '../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { DotWave } from '@uiball/loaders';
import Lottie from 'lottie-react-web';
import animation from '../../assets/lottie/confetti.json';
import buttonLoader from '../../assets/lottie/button_loader.json';
import tickImg from '../../assets/images/icons/success_tick.svg';
import { communityRedirect } from '../../services/communityService';
import NotificationService from '../../services/notificationService';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import { authLogout } from '../../store/actions/authActions';
import { deleteAuthCookie } from '../../constants/authUtils';
import AnalyticsService from '../../services/AnalyticsService';
import BookADemoImg from '../../assets/images/book_a_demo_creative.svg';
import { EVENTS } from '../../constants/Constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InlineWidget } from 'react-calendly';
import { Dialog, Transition } from '@headlessui/react';
import CrossIcon from '../../components/icons/CrossIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Calcom from '../../components/Calcom';

const CommunityRedirect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const data = useSelector((state) => state?.community?.createCommunityData);
  const communityData = useSelector((state) => state.community.createCommunityData);
  const [redirectToCommunity, setRedirectToCommunity] = useState(false);
  // const calendlyUrl = process.env.REACT_APP_CALENDLY_URL_TO_SCHEDULE_CALL;
  const [calendlyURL, setCalendlyURL] = useState(null);

  const localCommunityData = JSON.parse(sessionStorage.getItem('createCommunityData'));

  useEffect(() => {
    if (!localCommunityData?.id) {
      history.replace('/admin/login');
    }
  }, []);

  useEffect(() => {
    let calendlyPrefilledUrl;
    const URL = process.env.REACT_APP_CALENDLY_URL_TO_SCHEDULE_CALL;
    const prefillData = JSON.parse(sessionStorage.getItem('prefill-data'));
    if (prefillData && !_.isEmpty(prefillData)) {
      const { email, first_name, last_name } = prefillData;
      calendlyPrefilledUrl = `${URL}&name=${first_name} ${last_name}&email=${email}`;
    }
    setCalendlyURL(calendlyPrefilledUrl || URL);
  }, []);

  useEffect(() => {
    setRedirectToCommunity(false);
    setTimeout(function () {
      setIsLoading(false);
    }, 4000);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const redirectDashboard = async (clickedCrossBtn = false) => {
    setRedirectToCommunity(true);
    const additionalProps = {};

    if (clickedCrossBtn) {
      additionalProps['source'] = 'cross_button';
    } else {
      additionalProps['source'] = 'redirection_page';
    }

    await AnalyticsService.trackAmplitudeEventSync(EVENTS.AMPLITEUDE.ONBOARDING_TAKE_ME_TO_MY_COMMUNITY, {
      ...analyticsDefaults(),
      ...additionalProps,
    });

    try {
      sessionStorage.removeItem('prefill-data');

      let response;

      if (communityData?.data?.id) {
        response = await communityRedirect(communityData?.data?.id);
      } else {
        response = await communityRedirect(localCommunityData?.id);
      }
      const { status } = response;
      if (status >= 200 && status < 300) {
        dispatch(authLogout());
        localStorage.clear();
        deleteAuthCookie();
        window.location.href = response?.data?.redirect_uri;
      }
      sessionStorage.removeItem('createCommunityData');
    } catch (err) {
      console.log(err);
      NotificationService.error(parseAxiosErrorMessage(err));
      setRedirectToCommunity(false);
    }
  };

  const handleBookACall = () => {
    AnalyticsService.trackAmplitudeEvent(EVENTS.AMPLITEUDE.ONBOARDING_BOOK_A_DEMO_CLICKED, {
      ...analyticsDefaults(),
    });
    setIsOpen(true);
    return false;
  };

  const handleBookACallBack = () => {
    AnalyticsService.trackAmplitudeEvent(EVENTS.AMPLITEUDE.ONBOARDING_BOOK_A_DEMO_BACK_BUTTON_CLICKED, {
      ...analyticsDefaults(),
    });
    closeModal();
  };

  if (isLoading) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center w-full mobile:bg-white bg-none mobile:rounded-2xl mobile:h-full">
          <div className="text-center">
            <div className="flex items-center justify-center mb-3">
              <DotWave size={77} speed={1} color="#374151" />
            </div>
            <h2 className="text-2xl mb-1 font-bold text-gray-700">{$translatei18n('SettingUpCommunity')}</h2>
            <p className="text-md font-normal text-gray-600 mb-12">{$translatei18n('SettingUpCommunityMessage')}</p>
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="flex items-center justify-center flex-1 relative mobile:flex-col mobile:justify-between mobile:bg-white bg-none mobile:rounded-2xl h-full">
        <div className="flex absolute left-0 right-0 -top-14 lg:top-0 h-72">
          <Lottie
            options={{
              animationData: animation,
              autoplay: true,
              loop: false,
            }}
            width="100%"
            height="100%"
          />
        </div>
        <div
          className="text-center mobile:flex mobile:flex-col mobile:mt-6 mobile:h-full mobile:items-center"
          data-aos={'fade'}
          data-aos-delay={400}
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-anchor-placement="center"
        >
          <div className="lg:mb-6">
            <img src={tickImg} className="h-16 w-16 lg:h-24 lg:w-24 mx-auto" alt="success-tick" />
            <h2 className="text-24 lg:text-3xl mb-1 text-center w-72 lg:w-auto font-Inter font-bold text-gray-700">
              {$translatei18n('CommunitySetupComplete')}
            </h2>
          </div>
          <div className="p-8 mobile:pt-4 min-w-280 lg:w-758 rounded-2xl lg:border border-gray-200 flex flex-col lg:flex-row gap-8">
            <div className="bg-blue-50 h-180 lg:h-auto rounded-lg overflow-hidden">
              <img src={BookADemoImg} alt="book a demo banner" className="h-full w-full object-cover" />
            </div>
            <div className="flex-1 text-center lg:text-left">
              <h3 className="text-20 font-bold font-Inter text-gray-700 leading-7">
                {$translatei18n('DiscoverPowerOfCommunity')}
                <br />
                {$translatei18n('BookToday')}
              </h3>
              <p className="text-sm mt-2 text-gray-600">{$translatei18n('DiscoverPowerOfCommunityMessage')}</p>
              <div className="mt-4 flex flex-col gap-3 rounded-xl">
                <button
                  disabled={redirectToCommunity}
                  type="button"
                  className={`px-6 py-4 lg:w-60 border border-transparent text-sm leading-4 font-Inter font-bold rounded-xl shadow-sm text-white bg-secondayBlue hover:bg-indigo-700 focus:outline-none ${
                    redirectToCommunity ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                  }`}
                  onClick={handleBookACall}
                >
                  {$translatei18n('BookACall')}
                </button>
                <button
                  disabled={redirectToCommunity}
                  type="button"
                  className={`px-6 lg:w-60 border border-gray-300 text-sm leading-4 font-Inter font-bold rounded-xl shadow-sm text-gray-700 focus:outline-none ${
                    redirectToCommunity ? '' : 'py-4 opacity-100'
                  }`}
                  onClick={() => {
                    redirectDashboard();
                  }}
                >
                  {redirectToCommunity ? (
                    <span className={`w-12 h-12 inline-block ${redirectToCommunity ? 'cursor-not-allowed' : ''}`}>
                      <Lottie
                        options={{
                          animationData: buttonLoader,
                          autoplay: true,
                          loop: true,
                        }}
                        width="100%"
                        height="100%"
                      />
                    </span>
                  ) : (
                    $translatei18n('TakeMeToMyCommunity')
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" style={{ zIndex: 999 }} onClose={() => {}}>
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <div className="fixed top-0 bottom-0 left-0 right-0 w-full large:inset-0 overflow-y-auto flex items-end lg:items-center justify-center">
              <div className="overflow-auto mx-auto w-full flex items-end lg:items-center justify-center lg:h-auto rounded-t-xl lg:rounded-xl">
                <span
                  data-tooltip-html={$translatei18n('CrossIconTooltip')}
                  data-tooltip-id="cross-book-a-demo"
                  onClick={() => {
                    closeModal();
                    redirectDashboard(true);
                  }}
                  className="absolute p-2 top-4 lg:top-8 right-4 lg:right-8 z-50 cursor-pointer bg-white rounded-full"
                >
                  <CrossIcon color="#000" />
                </span>
                <ReactTooltip id="cross-book-a-demo" place="right" effect="solid" />
                <div className="bg-white rounded-t-lg lg:rounded-lg w-full md:w-758 lg:w-[1000px] inline-block h-[85vh] lg:h-auto overflow-hidden">
                  {/* <div className="flex items-center gap-3 p-4 cursor-pointer" onClick={handleBookACallBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                      <path
                        d="M12 19.65L6 13.05L12 6.44995"
                        stroke="#1F2937"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 13.05H19.2"
                        stroke="#1F2937"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <h2>{$translatei18n('BookACall')}</h2>
                  </div> */}
                  <div className='pt-4'>
                    <Calcom />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </MainLayout>
  );
};

export default CommunityRedirect;
