import React from 'react';
import icon1 from '../../assets/images/logo/unext.png';
import icon2 from '../../assets/images/logo/cloudnine-hospital-logo.png';
import icon3 from '../../assets/images/logo/motilal.png';
import { LockClosedIcon } from '@heroicons/react/solid';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import Truncate from 'react-truncate';

export default function CommunityCoverSignIn({ type }) {
  if (type === 1) {
    return (
      <div
        className="h-72 absolute top-0 right-0 left-0 w-11/12 mx-auto mt-4 bg-gray-100 border-b-2 border-gray-300 rounded-t-xl"
        style={{ border: '1px solid #F6F6F6' }}
      >
        <div className=" flex items-center justify-center bg-white rounded-xl" style={{ border: '1px solid #F6F6F6' }}>
          <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-2 mx-3 rounded-xl">
            <div className="flex items-center mx-auto">
              <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
              <p className="text-center">community-url.scenes.social</p>
            </div>
            <img src={reloadIcon} className="w-5 h-5 self-end" />
          </div>
        </div>
        <div className="border-b-2 border-gray-300" />
        <div className="p-4 px-10">
          <h1 className="text-center font-bold tracking-tighter text-base text-gray-900 mb-2">
            You’re in good company
          </h1>
          <p className="text-center text-xs text-gray-800">
            {/* <Truncate lines={3} ellipsis="..."> */}
            More than 2,000 creators and businesses trust Scenes with their community.
            {/* </Truncate> */}
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-3 px-12 my-2">
          <div className="flex items-center justify-evenly w-full">
            <img src={icon1} className="h-6" />
            <img src={icon2} className="h-6" />
            <img src={icon3} className="h-6" />
          </div>
        </div>
      </div>
    );
  }
}
