import { LockClosedIcon, XIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import RightPanel from '../layout/RightPanel';
import LeftMainPanel from '../layout/LeftMainPanel';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { loginSchema } from '../../schema';
import { authLogout, setAdminSignUp } from '../../store/actions/authActions';
import reloadIcon from '../../assets/images/icons/reload_icon.svg';
import graphyLogo from '../../assets/images/icons/graphy_brand_logo.svg';
import { adminLogin, googleSignInAdminRequest } from '../../services/authServices';
import NotificationService from '../../services/notificationService';
import { deleteAuthCookie, setAuthCookie } from '../../constants/authUtils';
import { setCommunityCreationDetails } from '../../store/actions/communityActions';
import { communityRedirect } from '../../services/communityService';
import { analyticsDefaults, parseAxiosErrorMessage } from '../../constants/utils';
import CommunityCoverSignIn from './CommunityCoverSignIn';

import icon1 from '../../assets/images/logo/unext.png';
import icon2 from '../../assets/images/logo/cloudnine-hospital-logo.png';
import icon3 from '../../assets/images/logo/motilal.png';
import PasswordComponent from '../login/PasswordComponent';
import GoogleLoginIcon from '../../components/icons/GoogleLoginIcon';
import { Constants } from '../../constants/Constants';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import AnalyticsService from '../../services/AnalyticsService';
import { ROUTE_PATH } from '../../constants';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState('');

  useEffect(() => {
    dispatch({
      type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
      payload: { name: 'Graphy Community Platform - Login to your Community' },
    });
  }, []);

  const responseGoogle = async (response) => {
    let valueObj = {
      access_token: response.tokenId,
    };

    try {
      const response = await googleSignInAdminRequest(valueObj);
      const { user, community: communities, token, refresh_token } = response;
      if (Object.keys(communities?.data)?.length === 0) {
        NotificationService.error($translatei18n('YouDontOwnACommunityPleaseCreateOne'));
        return;
      }
      NotificationService.success($translatei18n('LoginSuccess'));
      setAuthCookie(token, refresh_token?.crypted_token, user?.data?.id);
      dispatch(setAdminSignUp(response));
      dispatch(setCommunityCreationDetails(communities?.data));
      if (user?.data?.attributes?.voice_onboarding_status === Constants.VOICE_EMAIL_CONFIRMED) {
        history.push(ROUTE_PATH.ONBOARDING_PROFILE_INFO, {
          fname: user?.data?.attributes?.first_name,
          lname: user?.data?.attributes?.last_name,
          email: user?.data?.attributes?.email,
        });
      } else if (
        [Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(
          user?.data?.attributes?.voice_onboarding_status,
        ) &&
        communities?.data?.attributes?.community_status === Constants.COMMUNITY_SETUP_COMPLETED
      ) {
        const response = await communityRedirect(communities?.data?.id);
        const { status } = response;
        if (status >= 200 && status < 300) {
          dispatch(authLogout());
          localStorage.clear();
          deleteAuthCookie();
          window.location.href = response?.data?.redirect_uri;
        }
      } else if (
        [Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(
          user?.data?.attributes?.voice_onboarding_status,
        ) &&
        communities?.data?.attributes?.community_status === Constants.COMMUNITY_CREATED
      ) {
        history.push('/community/appearance');
      } else if (user?.data?.attributes?.voice_onboarding_status === 'voice_onboard_start') {
        history.push({
          pathname: '/admin/register/email/verify-otp',
          state: { resendOTP: true },
        });
        // handle properly
      } else {
        NotificationService.warning($translatei18n('LoginSuccessRedirectionErrorContactUs'));
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(
          parseAxiosErrorMessage(err) || $translatei18n('YouAlreadyHaveACommunityPleaseLogin'),
        );
        history.push('/admin/login');
      } else {
        console.log(err);
        NotificationService.error(parseAxiosErrorMessage(err) || $translatei18n('SomethingWentWrong'));
      }
    }
  };

  const failureGoogle = (error) => {
    console.error(error);
  };

  const handleGoogleSignInClick = (gProps) => {
    AnalyticsService.trackAmplitudeEvent('onboarding_google_signin_tapped', {
      ...analyticsDefaults(),
    });
    gProps.onClick();
  };

  const handleAdminLogin = async (values, actions) => {
    actions.setSubmitting(true);
    let valueObj = {
      user: {
        email: values?.email,
        password: values?.password,
      },
    };
    try {
      const response = await adminLogin(valueObj);
      const { status, data } = response;
      const { user, communities, token, refresh_token } = data;
      if (status >= 200 && status < 300 && data) {
        if (communities?.data?.length === 0) {
          NotificationService.error($translatei18n('YouDontOwnACommunityPleaseCreateOne'));
          return;
        }
        NotificationService.success($translatei18n('LoginSuccess'));
        setAuthCookie(token, refresh_token?.crypted_token, user?.data?.id);
        dispatch(setAdminSignUp(response?.data));
        dispatch(setCommunityCreationDetails(communities?.data[0]));
        if (
          [Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(
            user?.data?.attributes?.voice_onboarding_status,
          ) &&
          communities?.data[0]?.attributes?.community_status === Constants.COMMUNITY_SETUP_COMPLETED
        ) {
          const response = await communityRedirect(communities?.data[0]?.id);
          const { status } = response;
          if (status >= 200 && status < 300) {
            dispatch(authLogout());
            localStorage.clear();
            deleteAuthCookie();
            window.location.href = response?.data?.redirect_uri;
          }
        } else if (
          [Constants.VOICE_ONBOARDING_COMPLETED, Constants.VOICE_OTP_VERIFIED].includes(
            user?.data?.attributes?.voice_onboarding_status,
          ) &&
          communities?.data[0]?.attributes?.community_status === Constants.COMMUNITY_CREATED
        ) {
          history.push('/community/appearance');
        } else if (user?.data?.attributes?.voice_onboarding_status === 'voice_onboard_start') {
          history.push({
            pathname: '/admin/register/email/verify-otp',
            state: { resendOTP: true },
          });
          // handle properly
        } else {
          NotificationService.warning($translatei18n('LoginSuccessRedirectionErrorContactUs'));
        }
      }
    } catch (err) {
      if (err?.response?.data?.error_code === 49122) {
        NotificationService.warning(
          parseAxiosErrorMessage(err) || $translatei18n('YouAlreadyHaveACommunityPleaseLogin'),
        );
        history.push('/admin/login');
      } else {
        console.log(err);
        NotificationService.error(parseAxiosErrorMessage(err) || $translatei18n('SomethingWentWrong'));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const signInLeftView = (
    <>
      <div className="items-center hidden lg:flex">
        <img src={graphyLogo} alt="Graphy Community Platform Logo" className="h-5" />
      </div>
      <div className="flex-1 flex flex-col justify-center lg:flex-none">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <h2 className="text-24 font-bold text-gray-700 mt-6 tracking-tighter leading-tight">
              {$translatei18n('WelcomeBack')}
            </h2>
            <p className="text-14 text-gray-600 tracking-tighter leading-tight mt-1">
              {$translatei18n('SignInWithYourGraphyAccountToAccessYourCommunity')}
            </p>
          </div>

          <div className="mt-4">
            <div className="social__login">
              <div>
                <div className="mt-1 grid grid-cols-1 gap-2">
                  <div>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      scope="profile email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
                      render={(renderProps) => (
                        <button
                          type="button"
                          onClick={() => handleGoogleSignInClick(renderProps)}
                          disabled={renderProps.disabled}
                          className="w-full inline-flex justify-center p-3 btn__light rounded-xl text-sm font-medium cursor-pointer"
                        >
                          <span className="align-middle inline-block mr-1">
                            <GoogleLoginIcon />
                          </span>
                          <span className="font-semibold align-text-bottom font-Inter inline-block">
                            {$translatei18n('ContinuewithGoogle')}
                          </span>
                        </button>
                      )}
                      cookiePolicy="single_host_origin"
                      onSuccess={responseGoogle}
                      onFailure={failureGoogle}
                      accessType="offline"
                      prompt="consent"
                      fetchBasicProfile={false}
                      autoLoad={false}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center">
                <div className="flex-1 border-t divider" />
                <span className="px-2 text-xs text__description">{$translatei18n('orUseEmail')}</span>
                <div className="flex-1 border-t divider" />
              </div>
            </div>

            <div className="mt-6">
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={loginSchema}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  actions.validateField('email');
                  actions.validateField('password');
                  handleAdminLogin(values, actions);
                }}
              >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <form className="" onSubmit={handleSubmit}>
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                    <div className="rounded-md shadow-sm -space-y-px">
                      <div
                        className="mb-4"
                        data-aos={'fade'}
                        data-aos-delay={200}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                      >
                        <label htmlFor="email-address" className="block text-sm font-medium text__title mb-1">
                          {$translatei18n('EmailAddress')}
                        </label>

                        <Field
                          id="email-address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                          placeholder="Email address"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="email" render={CustomErrorMessage} />
                      </div>
                      <div
                        className="mb-4"
                        data-aos={'fade'}
                        data-aos-delay={400}
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        data-aos-anchor-placement="center"
                      >
                        <label htmlFor="password" className="block text-sm font-medium text__title mb-1">
                          {$translatei18n('Password')}
                        </label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          className="rounded-xl text-base text-gray-700 font-medium placeholder-gray-300 border-gray-300 w-full"
                          placeholder="Password"
                          setFieldValue={setFieldValue}
                          setPassword={setPassword}
                          onChange={handleChange}
                          value={values.password}
                          onBlur={handleBlur}
                          component={PasswordComponent}
                        />
                        <ErrorMessage name="password" render={CustomErrorMessage} />
                      </div>
                    </div>

                    <div className="flex items-center justify-between mb-4 mt-4">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-gray-400 focus:ring-offset-0 focus:ring-0 focus:ring-gray-300 border-gray-300 rounded text__link"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text__title">
                          {$translatei18n('RememberMe')}
                        </label>
                      </div>

                      <button
                        type="button"
                        className="text-sm cursor-pointer"
                        onClick={() => {
                          sessionStorage.setItem('forgotPasswordClicked', true);
                          history.push('/admin/forgot-password');
                        }}
                      >
                        <span className="font-semibold text-secondayBlue">{$translatei18n('ForgotYourPassword')}</span>
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 bg-secondayBlue hover:bg-indigo-700 text-white"
                      >
                        {$translatei18n('SignInToYourAccount')}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>

          <div className="mt-8 mb-4 relative">
            <div className="relative flex justify-center text-sm">
              <span className="px-2 text__description">
                {$translatei18n('DontHaveAnAccount')}
                <button
                  type="button"
                  className="text-secondayBlue ml-1 cursor-pointer font-semibold"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  {$translatei18n('SignUp')}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <MainLayout>
      <div className="block lg:hidden">
        <CommunityCoverSignIn type={1} />
        {/* //1 -> mobile, 2 -> desktop */}
      </div>

      <div className="min-h-full flex w-full">
        <div
          className="m-8 w-full hidden lg:block lg:w-1/3 2xl:w-1/3 flex flex-col px-1"
          style={{
            overflow: 'auto',
          }}
        >
          {signInLeftView}
        </div>
        <div className="lg:hidden">
          <LeftMainPanel>{signInLeftView}</LeftMainPanel>
        </div>
        <RightPanel>
          <div className="flex flex-1 items-center justify-center transition duration-500">
            <div
              className="w-5/6 bg-white rounded-3xl shadow-lg"
              data-aos={'fade'}
              data-aos-delay={300}
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="center"
            >
              <div className=" flex items-center justify-center">
                <div className="bg-gray-100 p-2 flex items-center justify-center w-full my-4 mx-6 rounded-xl">
                  <div className="flex items-center mx-auto">
                    <LockClosedIcon className="text-gray-400 w-5 h-5 mr-2 mt-0.5" />
                    <p className="text-center">{$translatei18n('yourCommunityUrlCom')}</p>
                  </div>
                  <img src={reloadIcon} className="w-5 h-5 self-end" />
                </div>
              </div>
              <div className="border-b-2 border-gray-300" />
              <div className="p-12">
                <h1 className="text-center font-bold tracking-tighter text-3xl text-gray-900 mb-2">
                  {$translatei18n('YoureInGoodCompany')}
                </h1>
                <p className="text-center text-20 text-gray-800">
                  {$translatei18n('MoreThan2000CreatorsAndBusinessesTrustScenesWithTheirCommunity')}
                </p>
              </div>
              <div className="flex flex-wrap justify-center gap-3 px-12 my-12">
                <div className="flex items-center justify-evenly w-full">
                  <img src={icon1} className="h-28" />
                  <img src={icon2} className="h-16" />
                </div>
                <div className="w-full">
                  <img src={icon3} className="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </RightPanel>
      </div>
    </MainLayout>
  );
};

export default Login;
