import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Constants } from '../../constants/Constants';

export default function AuthBaseLayout({
  children,
  headingTitle,
  headingSubTitle,
  onClose,
  imageCardStyle,
  isUsedInRegister,
  hideCard,
}) {
  const history = useHistory();
  const handleBackNavigation = () => {
    history.goBack();
  };

  const user = useSelector((state) => state.user);
  const isLogin = useSelector((state) => state.auth && state.auth.token);
  const activeCommunity = useSelector((state) => state?.community?.activeCommunity);
  const hostedCommunities = useSelector((state) => state?.community?.hostedCommunities);

  React.useEffect(() => {
    if (isLogin && user && user.attributes && user.attributes.voice_onboarding_status) {
      const currentOnboardingStatus = user.attributes.voice_onboarding_status;

      switch (currentOnboardingStatus) {
        case Constants.VOICE_ONBOARDING_COMPLETED:
          // if (Array.isArray(hostedCommunities) && hostedCommunities.length > 0 && activeCommunity) {
          //   // history.push(`/communities/${activeCommunity}`);
          // } else {
          //   history.push('/onboarding/create-community');
          // }

          break;

        case 'voice_onboard_start':
          history.push('/register/email/verify-otp');
          break;

        case Constants.VOICE_EMAIL_CONFIRMED:
        case 'voice_otp_sent':
          history.push('/onboarding/mobile');
          break;

        case Constants.VOICE_OTP_VERIFIED:
          history.push('/onboarding/create-account');
          break;

          // case 'voice_contacts_synced':
          //   history.push('/onboarding/invite-friend');
          //   break;

        default:
          // Nothing to do
          break;
      }
    }
  }, [user, isLogin, activeCommunity, hostedCommunities]);

  return <div className="overflow-scroll">{children}</div>;
}
