import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { LOGIN_IFRAME_ID } from '../constants';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { parseAxiosErrorMessage, parseAxiosErrorMessageStage } from '../constants/utils';
import AnalyticsService from '../services/AnalyticsService';
import {
  appleSignInRequest,
  bubbleSignInRequest,
  checkEmailExists,
  googleSignInRequest,
  login,
  registerWithEmail,
} from '../services/authServices';
import NotificationService from '../services/notificationService';
import {
  APPLE_SIGN_IN_REQUEST,
  BUBBLE_SIGN_IN_FAILED,
  BUBBLE_SIGN_IN_REQUEST,
  BUBBLE_SIGN_IN_SUCCESS,
  CHECK_EMAIL_FAILED,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  GOOGLE_SIGN_IN_FAILED,
  GOOGLE_SIGN_IN_REQUEST,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  SIGN_UP_WITH_EMAIL_FAILED,
  SIGN_UP_WITH_EMAIL_REQUEST,
} from '../store/actions/actionTypes';
import {
  authSetRefreshToken,
  authSetToken,
  deleteCheckEmail,
  setOnboarded,
  setRoomToken,
  setUserEmail,
  setUserId,
} from '../store/actions/authActions';
import { setUser } from '../store/actions/userActions';
import { postCrossDomainMessage } from '../views/login/loginUtils';

export function* loginRequest() {
  yield takeLatest(LOGIN_REQUEST, function* ({ payload }) {
    try {
      const response = yield login(payload);
      window.location.href = response?.redirect_uri;

      // const { token, user, refresh_token } = response;

      // if (token) {
      //   yield put(authSetToken(response.token));
      //   yield put(setUserEmail(payload.email));
      //   yield put(deleteCheckEmail());
      // }

      // if (refresh_token) {
      //   yield put(authSetRefreshToken(refresh_token.crypted_token));
      // }

      // if (user) {
      //   AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
      //   AnalyticsService.setUser(user.data.attributes.email);
      //   AnalyticsService.logMixpanelEvent('Signed In', { with: 'email' });
      //   if (user.data.attributes.id) {
      //     yield put(setUserId(user.data.attributes.id));
      //   }
      //   if (user.data.attributes.room_token) {
      //     yield put(setRoomToken(user.data.attributes.room_token));
      //   }
      //   yield put(setOnboarded(user.data.attributes.onboarding_status));
      //   yield put(setUser(user.data));
      // }
      // yield put({
      //   type: LOGIN_SUCCESS,
      //   payload: response,
      // });

      // if (
      //   payload.requestHost &&
      //   (user?.data?.attributes?.voice_onboarding_status === 'voice_contacts_synced' ||
      //     user?.data?.attributes?.voice_onboarding_status === 'voice_onboarding_completed')
      // ) {
      //   postCrossDomainMessage(
      //     LOGIN_IFRAME_ID,
      //     {
      //       token: response.token,
      //       refreshToken: refresh_token.crypted_token,
      //       userId: user?.data?.id,
      //     },
      //     payload.requestHost,
      //   );
      //   setTimeout(() => {
      //     let redirectUri = `https://${payload.requestHost}`;
      //     if (payload.redirectUrl) {
      //       redirectUri = payload.redirectUrl;
      //     }
      //     window.location.href = redirectUri;
      //   }, 0);
      // }

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessageStage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: LOGIN_FAILED,
        payload: {},
      });
    }
  });
}

export function* checkEmailRequest() {
  yield takeLatest(CHECK_EMAIL_REQUEST, function* ({ payload }) {
    try {
      yield checkEmailExists(payload);

      yield put({
        type: CHECK_EMAIL_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      const message = parseAxiosErrorMessageStage(error);
      NotificationService.error(message);
      yield put({
        type: CHECK_EMAIL_FAILED,
        payload: payload,
      });
    }
  });
}

export function* signUpWithEmail() {
  yield takeLatest(SIGN_UP_WITH_EMAIL_REQUEST, function* ({ payload }) {
    const { data, setLoading } = payload;
    try {
      const response = yield registerWithEmail(data);
      window.location.href = response?.redirect_uri;
      setLoading(false);
      // const { token, user, refresh_token } = response;

      // if (token) {
      //   yield put(authSetToken(response.token));
      //   yield put(setUserEmail(payload.email));
      //   yield put(deleteCheckEmail());
      // }

      // if (refresh_token) {
      //   yield put(authSetRefreshToken(refresh_token.crypted_token));
      // }

      // if (user) {
      //   AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
      //   AnalyticsService.setUserAttributes(user.data.attributes);
      //   AnalyticsService.logMixpanelEvent('Signed Up', { with: 'email' });

      //   if (user.data.attributes.id) {
      //     yield put(setUserId(user.data.attributes.id));
      //   }
      //   if (user.data.attributes.room_token) {
      //     yield put(setRoomToken(user.data.attributes.room_token));
      //   }
      //   yield put(setOnboarded(user.data.attributes.onboarding_status));
      //   yield put(setUser(user.data));
      // }
      // yield put({
      //   type: SIGN_UP_WITH_EMAIL_SUCCESS,
      //   payload: response,
      // });

      // if (payload?.requestHost) {
      //   postCrossDomainMessage(
      //     LOGIN_IFRAME_ID,
      //     {
      //       token: response?.token,
      //       refreshToken: refresh_token?.crypted_token,
      //       userId: user?.data?.id,
      //     },
      //     payload.requestHost,
      //   );
      // }

      NotificationService.success(SUCCESS_MESSAGES.SIGNUP_SUCCESS);
    } catch (error) {
      setLoading(false);
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: SIGN_UP_WITH_EMAIL_FAILED,
        payload: payload,
      });
    }
  });
}

export function* googleSignIn() {
  yield takeLatest(GOOGLE_SIGN_IN_REQUEST, function* ({ payload }) {
    try {
      const response = yield googleSignInRequest(payload);

      window.location.href = response?.redirect_uri;
      // yield put({
      //   type: GOOGLE_SIGN_IN_SUCCESS,
      //   payload: response,
      // });

      // const { token, user, refresh_token } = response;

      // if (token) {
      //   yield put(authSetToken(response.token));
      //   yield put(setUserEmail(payload.email));
      //   yield put(deleteCheckEmail());
      // }

      // if (refresh_token) {
      //   yield put(authSetRefreshToken(refresh_token.crypted_token));
      // }

      // if (user) {
      //   AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
      //   AnalyticsService.setUserAttributes(user.data.attributes);
      //   AnalyticsService.logMixpanelEvent('Signed In', { with: 'google' });

      //   if (user.data.attributes.id) {
      //     yield put(setUserId(user.data.attributes.id));
      //   }
      //   if (user.data.attributes.room_token) {
      //     yield put(setRoomToken(user.data.attributes.room_token));
      //   }
      //   yield put(setOnboarded(user.data.attributes.onboarding_status));
      //   yield put(setUser(user.data));
      // }

      // if (
      //   payload.requestHost &&
      //   (user?.data?.attributes?.voice_onboarding_status === 'voice_contacts_synced' ||
      //     user?.data?.attributes?.voice_onboarding_status === 'voice_onboarding_completed')
      // ) {
      //   postCrossDomainMessage(
      //     LOGIN_IFRAME_ID,
      //     {
      //       token: response.token,
      //       refreshToken: refresh_token.crypted_token,
      //       userId: user?.data?.id,
      //     },
      //     payload.requestHost,
      //   );
      //   setTimeout(() => {
      //     let redirectUri = `https://${payload.requestHost}`;
      //     if (payload.redirectUrl) {
      //       redirectUri = payload.redirectUrl;
      //     }
      //     window.location.href = redirectUri;
      //   }, 0);
      // }

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: GOOGLE_SIGN_IN_FAILED,
      });
    }
  });
}

export function* appleSignIn() {
  yield takeLatest(APPLE_SIGN_IN_REQUEST, function* ({ payload }) {
    try {
      const response = yield appleSignInRequest(payload);

      window.location.href = response?.redirect_uri;
      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();
    }
  });
}

export function* bubbleSignIn() {
  yield takeLatest(BUBBLE_SIGN_IN_REQUEST, function* ({ payload }) {
    try {
      const response = yield bubbleSignInRequest(payload);

      yield put({
        type: BUBBLE_SIGN_IN_SUCCESS,
        payload: response,
      });

      const { token, user, refresh_token } = response;
      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(user?.data?.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);
        AnalyticsService.logMixpanelEvent('Signed In', { with: 'bubble' });

        if (user.data.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user.data.attributes.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data));
      }

      if (
        payload.requestHost /* &&
        (user?.data?.attributes?.voice_onboarding_status === 'voice_contacts_synced' ||
        user?.data?.attributes?.voice_onboarding_status === 'voice_onboarding_completed') */
      ) {
        postCrossDomainMessage(
          LOGIN_IFRAME_ID,
          {
            token: response.token,
            refreshToken: refresh_token.crypted_token,
            userId: user?.data?.id,
          },
          payload.requestHost,
        );
        setTimeout(() => {
          let redirectUri = `https://${payload.requestHost}`;
          if (payload.redirectUrl) {
            redirectUri = payload.redirectUrl;
          }
          window.location.href = redirectUri;
        }, 0);
      }

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: BUBBLE_SIGN_IN_FAILED,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(checkEmailRequest),
    fork(signUpWithEmail),
    fork(googleSignIn),
    fork(bubbleSignIn),
    fork(appleSignIn),
  ]);
}
