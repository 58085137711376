import BubbleCallback from '../views/login/BubbleCallback';
import ForgotPassword from '../views/login/ForgotPassword';
import Login from '../views/login/Login';
import ResetPassword from '../views/login/ResetPassword';
import Signup from '../views/login/Signup';

import ChooseCommunityType from "../views/createCommunity/ChooseCommunityType"
import NameCommunity from '../views/createCommunity/NameCommunity';
import AdminSignup from "../views/admin/Signup"
import AdminLogin from "../views/admin/Login"
import AdminForgotPassword from "../views/admin/ForgotPassword"
import AdminVerifyNewPassword from "../views/admin/VerifyResetOTP"
import AdminEmailVerify from "../views/admin/VerifyEmailOTP"
import Appearance from '../views/createCommunity/Appearance';
import CommunityRedirect from '../views/createCommunity/CommunityRedirect';
import { ROUTE_PATH } from '../constants';
import OnboardingProfileInfo from '../views/admin/OnboardingProfileInfo';
import GoogleAuthenticate from '../components/auth/GoogleAuthenticate';

const indexRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/oauth2/callback',
    component: BubbleCallback,
    exact: true,
  },
  {
    path: '/authenticate',
    component: GoogleAuthenticate,
    exact: true
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/reset-password/:email/:reset_token',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/admin/login',
    component: AdminLogin,
    exact: true,
  },
  {
    path: '/admin/signup',
    component: AdminSignup,
    exact: true,
  },
  {
    path: '/admin/forgot-password',
    component: AdminForgotPassword,
    exact: true,
  },
  {
    path: '/admin/new-password',
    component: AdminVerifyNewPassword,
    exact: true,
  },
  {
    path: '/admin/reset-password/:email/:reset_token',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/',
    component: ChooseCommunityType,
    exact: true,
  },
  {
    path: '/community/choose-name',
    component: NameCommunity,
    exact: true,
  },
  {
    path: '/admin/register/email/verify-otp',
    component: AdminEmailVerify,
    exact: true,
    isAdminProtected: true
  },
  {
    path: '/community/appearance',
    component: Appearance,
    exact: true,
    isAdminProtected: true
  },
  {
    path: '/community/redirect',
    component: CommunityRedirect,
    exact: true,
    isAdminProtected: true
  },
  {
    path: ROUTE_PATH.ONBOARDING_PROFILE_INFO,
    component: OnboardingProfileInfo,
    exact: true,
    // isAdminProtected: false
  },
];

export default indexRoutes;
