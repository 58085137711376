import _ from 'lodash';
import { SET_PROGRESS_BAR_COMMUNITY_CREATION } from '../actions/actionTypes';

const initialState = {
  progress: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PROGRESS_BAR_COMMUNITY_CREATION:
      return { ...state, progress : action?.payload };

    default:
      return state;
  }
};

export default reducer;
