import Cookies from 'js-cookie';

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/` + '; SameSite=None; Secure';
}

const AUTH_COOKIE_NAME = 'self-serve-community-creation';

export function deleteAuthCookie() {
  document.cookie = `${AUTH_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const setCookieSync = (name, value, days) => {
  const date = new Date();
  const config = {
    sameSite: 'none',
    secure: true,
    path: '/',
  };
  if (days) {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    config.expires = date;
  }
  Cookies.set(name, value, config);
};

export const getAuthCookie = () => {
  // Sync Call
  const authCookie = Cookies.get(AUTH_COOKIE_NAME);
  try {
    return JSON.parse(authCookie);
  } catch (error) {
    return null;
  }
};

export const getAuthToken = () => {
  const authCookie = getAuthCookie();
  return authCookie?.token;
};

export const getRefreshToken = () => {
  const authCookie = getAuthCookie();
  return authCookie?.refreshToken;
};

export const getUserIdFromAuthCookie = () => {
  const authCookie = getAuthCookie();
  return authCookie?.userId;
};

export const updateAuthToken = (token) => {
  const authCookie = getAuthCookie();
  if (!authCookie) {
    return;
  }
  setCookieSync(AUTH_COOKIE_NAME, JSON.stringify({ ...authCookie, token }), 30);
};

export const updateRefreshToken = (refreshToken) => {
  const authCookie = getAuthCookie();
  if (!authCookie) {
    return;
  }
  setCookieSync(AUTH_COOKIE_NAME, JSON.stringify({ ...authCookie, refreshToken }), 30);
};

export const setAuthCookie = (token, refreshToken, userId) => {
  setCookieSync(
    AUTH_COOKIE_NAME,
    JSON.stringify({
      token,
      refreshToken,
      userId,
    }),
    30,
  );
};
