import { combineReducers } from 'redux';
import auth from './auth';
import communityReducer from './communityReducer';
import selfServeReducer from './selfServeReducer';
import ProgressBarReducer from "./progressBarReducer";
import { persistReducer } from 'redux-persist';
import { communityPersistConfig } from '../configureStore';
import User from "./userReducer"

const createReducer = (asyncReducer) =>
  combineReducers({
    auth,
    selfServeReducer,
    ProgressBarReducer,
    User,
    community: persistReducer(communityPersistConfig, communityReducer),
    ...asyncReducer,
  });

export default createReducer;
