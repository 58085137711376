import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthBaseLayout from '../../components/auth/BaseLayout';
import LoadingSpinner from '../../components/shared/tailwind/LoadingSpinner';
import { LOGIN_IFRAME_ID } from '../../constants';
import { getRequestHostFromCommunityPublicDetails } from '../../selectors/CommunitySelectors';
import { fetchPublicCommunityDetails } from '../../services/communityService';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import { authLogout, bubbleSignIn } from '../../store/actions/authActions';
import { createIframe } from './loginUtils';

export default function Login() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [isDomainVerified, setIsDomainVerified] = useState(false);

  const request_host = localStorage.getItem('request_host');
  const redirect_url = localStorage.getItem('redirect_url');
  const requestHostFromPublicDetails = useSelector(getRequestHostFromCommunityPublicDetails);
  const community_id = useSelector((state) => state?.community?.publicCommunityDetails?.id);

  const fetchPublicDetails = useCallback(async () => {
    try {
      const response = await fetchPublicCommunityDetails({ host: request_host });
      let payload = { ...response?.data };
      if (request_host) {
        payload.request_host = request_host;
      }
      if (redirect_url) {
        payload.redirect_url = redirect_url;
      }
      dispatch({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload,
      });
      setIsDomainVerified(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, [request_host, redirect_url]);

  useEffect(() => {
    if (requestHostFromPublicDetails && requestHostFromPublicDetails !== request_host) {
      dispatch(authLogout());
    }

    if (request_host) {
      createIframe(LOGIN_IFRAME_ID, `https://${request_host}/getlocalstorage.html`);
      fetchPublicDetails();
    } else {
      setIsDomainVerified(false);
      setIsLoading(false);
    }

    return () => {
      if (localStorage.getItem('redirect_host')) {
        localStorage.removeItem('redirect_host');
      }
      if (localStorage.getItem('redirect_url')) {
        localStorage.removeItem('redirect_url');
      }
    };
  }, []);

  useEffect(() => {
    if (isDomainVerified && !isLoading) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let code = params.get('code');

      let payload = { access_code: code, community_id: community_id };
      if (request_host) {
        payload.requestHost = request_host;
      }
      if (redirect_url) {
        payload.redirectUrl = redirect_url;
      }

      dispatch(bubbleSignIn(payload));
    }
  }, [isLoading, isDomainVerified]);

  return <LoadingSpinner />;
}
