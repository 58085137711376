import React from 'react'

export default function GoogleLoginIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_958_35534)">
        <path d="M19.7618 10.1878C19.7618 9.36841 19.6954 8.77047 19.5515 8.15039H10.1948V11.8487H15.687C15.5763 12.7678 14.9783 14.1519 13.6496 15.082L13.6309 15.2058L16.5893 17.4976L16.7943 17.5181C18.6767 15.7796 19.7618 13.2218 19.7618 10.1878Z" fill="#4285F4" />
        <path d="M10.1952 19.9322C12.8859 19.9322 15.1447 19.0463 16.7947 17.5183L13.6499 15.0822C12.8084 15.669 11.6789 16.0787 10.1952 16.0787C7.55982 16.0787 5.3231 14.3403 4.52577 11.9375L4.4089 11.9474L1.33271 14.3281L1.29248 14.44C2.93126 17.6954 6.29745 19.9322 10.1952 19.9322Z" fill="#34A853" />
        <path d="M4.52526 11.9366C4.31488 11.3165 4.19313 10.6521 4.19313 9.96559C4.19313 9.27902 4.31488 8.61467 4.51419 7.9946L4.50862 7.86254L1.39389 5.4436L1.29198 5.49208C0.616561 6.84299 0.229004 8.36002 0.229004 9.96559C0.229004 11.5712 0.616561 13.0881 1.29198 14.439L4.52526 11.9366Z" fill="#FBBC05" />
        <path d="M10.1952 3.85336C12.0664 3.85336 13.3287 4.66168 14.0485 5.33718L16.861 2.59107C15.1337 0.985496 12.8858 0 10.1952 0C6.29744 0 2.93126 2.23672 1.29248 5.49214L4.51469 7.99466C5.32309 5.59183 7.55981 3.85336 10.1952 3.85336Z" fill="#EB4335" />
      </g>
      <defs>
        <clipPath id="clip0_958_35534">
          <rect width="19.544" height={20} fill="white" transform="translate(0.228027)" />
        </clipPath>
      </defs>
    </svg>

  )
}
