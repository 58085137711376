import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').label('Email').required('Please enter your email'),
  password: Yup.string().label('Password').required('Please enter your password'),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').label('Email').required('Please enter your email'),
});

export const forgotPasswordVerifySchema = Yup.object().shape({
  password: Yup.string().label('Password').required('Please enter your password').min(6, "Password should be minimum 6 charcters."),
  confirm_password: Yup.string()
    .required("Please re-type your password")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});

export const continueWithEmailSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').label('Email').required('Please enter your email'),
});

